import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Drawer,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import { RiCloseLine } from "react-icons/ri";
import { GoDotFill } from "react-icons/go";
import { BsCheckAll } from "react-icons/bs";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import "../../../styles/announcement.scss";
import { useDispatch, useSelector } from "react-redux";
import AnnouncementModal from "../announcementModel";
import CampaignIcon from "@mui/icons-material/Campaign";
import { format } from "date-fns";
import { enGB } from "date-fns/locale";

import AnnouncementModal2 from "../announcementModel2";
import { callApi } from "../annoucementApi";
import {
  setMarkedAsReadId,
  setTotalAnnouncements,
} from "../../../store/slices/announcementSlice";
// ... other unchanged code
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) {
    return text;
  }
  return text.slice(0, maxLength) + "...";
};
export default function AnnouncementDrawer({
  openDrawer,
  setOpenDrawer,
  allAnnouncements,
  unreadAnnouncements,
  fetchAllAnnouncements,
  totalAnnouncements,
  totalCount,
  page,
  setPage,
  markAllFlag,
  setmarkAllFlag,
  settotalAnnouncements,
}) {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(0);
  const userId = Cookies.get(process.env.REACT_APP_USER_ID_COOKIE);
  const [readAnnouncements, setReadAnnouncements] = useState(false);
  const [openReadAllModal, setopenReadAllModal] = useState(false);
  const [loading, setLoadingg] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [viewAnnouncementOpen, setViewAnnouncementOpen] = useState(false);
  const [announcementIds, setAnnouncementIds] = useState([]);
  const handleScroll = (e) => {
    const container = e.target;
    const bottomMargin = 1;
    const bottom =
      container.scrollHeight - container.scrollTop <=
      container.clientHeight + bottomMargin;

    if (bottom && !loading) {
    }
  };

  useEffect(() => {
    if (unreadAnnouncements) {
      const values = Object.values(unreadAnnouncements);
      const unformattedData = values.map((announcements) =>
        announcements.map((announcement) => announcement.id)
      );
      const formattedData = unformattedData.flat();
      setAnnouncementIds(formattedData);
    }
  }, [unreadAnnouncements]);
  const navigate = useNavigate();
  const extractTextFromHTML = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "do MMMM yyyy", { locale: enGB });
  };
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setHasMore(true);
    if (markAllFlag) {
      setmarkAllFlag(false);
    }
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
    setSelectedTab(0);
  };

  useEffect(() => {
    fetchAllAnnouncements();
  }, [readAnnouncements]);

  const [announcement, setAnnouncement] = useState("");

  const AnnouncementItem = ({ announcement, day }) => (
    <Box
      display="flex"
      className="announcement-item"
      sx={{ position: "relative", cursor: "pointer" }}
      onClick={async () => {
        try {
          setViewAnnouncementOpen(true);
          setAnnouncement({
            profileImage: <CampaignIcon />,
            name: announcement.title,
            date: formatDate(announcement.createdAt),
            content: announcement.description,
            authorName:
              announcement.authorInfo.first_name +
              " " +
              announcement.authorInfo.last_name,
          });

          // API call wrapped in try-catch block
          await axios.patch(
            `${process.env.REACT_APP_LOCAL_CENTRAL_URL}/announcement/${userId}/read`,
            {
              announcement_id: announcement.id,
              is_all_read: false,
              moduleId: 3,
            }
          );
          dispatch(setMarkedAsReadId(announcement.id));
          setReadAnnouncements((prev) => !prev);
          const response = await callApi({
            method: "get",
            url: `${process.env.REACT_APP_LOCAL_CENTRAL_URL}/announcement/module/3`,
          });

          dispatch(setTotalAnnouncements(response?.data?.unreadCount));
        } catch (error) {
          console.error("Failed to update announcement read status:", error);
        }
      }}
    >
      {announcement?.is_read && !announcement.is_read.includes(+userId) && (
        <Tooltip title="Unread" arrow>
          <Box
            sx={{
              position: "absolute",
              top: 15,
              right: 8,
              display: "flex",
            }}
          >
            <GoDotFill
              fill="#7cb900"
              style={{
                color: "red",
                fontSize: 12,
              }}
            />
          </Box>
        </Tooltip>
      )}
      <Avatar
        src={announcement?.senderUser?.profile_img_url}
        alt={announcement?.senderUser?.first_name || "Sender"}
        sx={{ width: 38, height: 38, mr: 2, mt: 0.5 }}
      />
      <Box className="announcement-content">
        <Typography
          variant="subtitle1"
          className="subject"
          sx={{ fontWeight: !announcement?.is_read && 600 }}
        >
          {announcement?.title}
        </Typography>
        <Typography className="details">
          {announcement?.description &&
            truncateText(extractTextFromHTML(announcement?.description), 60)}
        </Typography>
        <Typography variant="caption" className="created-at">
          <span></span>
          <span>
            {day === "Older"
              ? new Date(announcement?.createdAt).toLocaleDateString()
              : new Date(announcement?.createdAt).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
          </span>
        </Typography>
      </Box>
    </Box>
  );

  const AnnouncementSection = ({ title, announcements }) => (
    <Box className="announcement-section">
      <Typography variant="span" sx={{ fontSize: "15px", fontWeight: 600 }}>
        {title}
      </Typography>
      {announcements?.length > 0
        ? announcements?.map((announcement) => (
            <AnnouncementItem
              key={announcement?.id}
              announcement={announcement}
              day={title}
            />
          ))
        : null}
    </Box>
  );

  const hasAnyannouncements = (announcements) =>
    announcements?.todayAnnouncements?.length > 0 ||
    announcements?.yesterdayAnnouncements?.length > 0 ||
    announcements?.otherAnnouncements?.length > 0;

  const SeeAllAnnouncementsButton = () => (
    <Box textAlign="center" my={2}>
      <Button
        variant="contained"
        className="primary-btn btn w-60 btn"
        sx={{
          backgroundColor: "#7cb900",
          textTransform: "none",
          color: "white",
          "&:hover": {
            backgroundColor: "#689b00",
          },
        }}
        onClick={() => navigate("/announcements")}
      >
        See All Announcements
      </Button>
    </Box>
  );

  return (
    <>
      <Drawer
        className="side-drawer-2 report-side-drawer drawer-container announcement-drawer"
        anchor="right"
        open={openDrawer}
        onClose={handleCloseDrawer}
      >
        <Box className="announcement-header">
          <Box className="sidebar-tab-wrapper-2">
            <Box className="close-btn">
              <RiCloseLine onClick={handleCloseDrawer} />
            </Box>
            <Box className="sidebar-tab">
              <Tab
                className="tab-label-btn contact-label"
                label="Announcements"
                disabled
              />
            </Box>
          </Box>
          <Box className="sidebar-tab-wrapper-2">
            <Box className="tabs-icons">
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                aria-label="basic tabs example"
                sx={{
                  "& .MuiTab-root": {
                    color: "grey",
                    textTransform: "none",
                  },
                  "& .Mui-selected": {
                    color: "black !important",
                  },
                  "& .MuiTabs-indicator": {
                    backgroundColor: "#7cb900",
                    height: "3px",
                  },
                }}
              >
                <Tab label="All" {...a11yProps(0)} />
                <Tab label="Unread" {...a11yProps(1)} />
              </Tabs>
              <Box className="icons">
                {totalAnnouncements !== 0 && (
                  <Tooltip title="Mark all as read" arrow>
                    <Box>
                      <IconButton onClick={() => setopenReadAllModal(true)}>
                        <BsCheckAll size={24} fill="#7cb900" />
                      </IconButton>
                    </Box>
                  </Tooltip>
                )}
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          className="announcement-container"
          sx={{
            overflowY: "auto",
            maxHeight: "calc(100vh - 105px)",
          }}
          onScroll={handleScroll}
        >
          <CustomTabPanel value={selectedTab} index={0}>
            {hasAnyannouncements(allAnnouncements) ? (
              <>
                {allAnnouncements?.todayAnnouncements?.length > 0 && (
                  <AnnouncementSection
                    title="Today"
                    announcements={allAnnouncements?.todayAnnouncements}
                  />
                )}
                {allAnnouncements?.yesterdayAnnouncements?.length > 0 && (
                  <AnnouncementSection
                    title="Yesterday"
                    announcements={allAnnouncements?.yesterdayAnnouncements}
                  />
                )}
                {allAnnouncements?.otherAnnouncements?.length > 0 && (
                  <AnnouncementSection
                    title="Older"
                    announcements={allAnnouncements?.otherAnnouncements}
                  />
                )}
                {/* Add the See All Announcements button here */}

                {<SeeAllAnnouncementsButton />}
              </>
            ) : (
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{
                  textAlign: "center",
                  marginTop: "20px",
                  fontSize: "15px",
                  position: "absolute",
                  top: "50%",
                  left: "30%",
                }}
              >
                No announcements found
              </Typography>
            )}
          </CustomTabPanel>

          <CustomTabPanel value={selectedTab} index={1}>
            {hasAnyannouncements(unreadAnnouncements) ? (
              <>
                {unreadAnnouncements?.todayAnnouncements?.length > 0 && (
                  <AnnouncementSection
                    title="Today"
                    announcements={unreadAnnouncements?.todayAnnouncements}
                  />
                )}
                {unreadAnnouncements?.yesterdayAnnouncements?.length > 0 && (
                  <AnnouncementSection
                    title="Yesterday"
                    announcements={unreadAnnouncements?.yesterdayAnnouncements}
                  />
                )}
                {unreadAnnouncements?.otherAnnouncements?.length > 0 && (
                  <AnnouncementSection
                    title="Older"
                    announcements={unreadAnnouncements?.otherAnnouncements}
                  />
                )}
                {/* Add the See All Announcements button here */}
                {<SeeAllAnnouncementsButton />}
              </>
            ) : (
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{
                  textAlign: "center",
                  marginTop: "20px",
                  fontSize: "15px",
                  position: "absolute",
                  top: "50%",
                  left: "30%",
                }}
              >
                No announcements found
              </Typography>
            )}
          </CustomTabPanel>
        </Box>

        {openReadAllModal && (
          <AnnouncementModal
            openReadAllModal={openReadAllModal}
            setopenReadAllModal={setopenReadAllModal}
            setPage={setPage}
            setmarkAllFlag={setmarkAllFlag}
            settotalAnnouncements={settotalAnnouncements}
            selectedTab={selectedTab}
            setHasMore={setHasMore}
            userId={userId}
            announcementIds={announcementIds}
            setReadAnnouncements={setReadAnnouncements}
          />
        )}
      </Drawer>
      <AnnouncementModal2
        announcement={announcement}
        viewAnnouncementOpen={viewAnnouncementOpen}
        setViewAnnouncementOpen={setViewAnnouncementOpen}
      ></AnnouncementModal2>
    </>
  );
}
