import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  Grid,
  Typography,
  Divider,
  Stack,
  Drawer,
  Tabs,
  Tab,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
  Tooltip,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { RiDeleteBinLine, RiEyeLine, RiPencilLine } from "react-icons/ri";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  RiAlarmLine,
  RiArrowLeftLine,
  RiBillLine,
  RiCloseLine,
  RiAddCircleLine,
} from "react-icons/ri";
import PropTypes from "prop-types";
import {
  fetchAssetById,
  fetchAssetTicketsByAsset,
  fetchAssetActivity,
} from "../../../store/slices/assetSlice";
import { useDispatch } from "react-redux";
import moment from "moment";
import Loader from "../../../components/common/Loader";
import {
  formatDefaultPrice,
  getUserRole,
  roundToTwoDecimal,
} from "../../../utils/helper";
import AssetTicketsDeleteModal from "../../../components/common/AssetTicketsDeleteModal";
import {
  fullAssetTicket,
  updateAssetTicket,
  viewAssetTicket,
} from "../../../utils/permissions";
import WithPermission from "../../../components/common/withPermission";

// START :: SIDEBAR DRAWER TABBING PANEL
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Box>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// END :: SIDEBAR DRAWER TABBING PANEL

const AssetsView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  let { state } = location;
  if (state === null) {
    state = {
      childvalue: 0,
      currentPage: 1,
      rowperPage: 10,
      searchAsset: "",
      filterApplied: false,
      asset_owner: "0",
    };
  }
  const [loader, setLoader] = useState(false);
  const [asset, setAsset] = useState([]);
  const [assetTickets, setAssetTickets] = useState([]);
  const [activity, setActivity] = useState([]);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [assetTicketId, setAssetTicketId] = useState("");
  const [assetTicketsDelete, setAssetTicketsDelete] = useState(false);
  const { id } = useParams();
  const role = getUserRole();
  useEffect(() => {
    fetchAssetTicketList();
    fetchData();
    fetchActivity();
  }, [id]);

  const getStatusValueString = (value) => {
    switch (value) {
      case "in_service":
        return "In Service";
      case "under_repair":
        return "Under Repair"; 
      case "out_of_service":
        return "Out Of Service";
      case "in_stock_available":
        return "In Stock";
      case "scrap":
        return "Scrap";
      case "returned":
          return "Returned";  
      default:
        return "In Service";
    }
  };

  const fetchAssetTicketList = async () => {
    setLoader(true);
    try {
      const response = await dispatch(fetchAssetTicketsByAsset(id));
      if (response?.success) {
        setAssetTickets(response?.data);
        setCount(response?.data?.totalPages);
      } else {
        console.error("Failed! Something went wrong.");
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };

  const fetchData = async () => {
    setLoader(true);
    try {
      const response = await dispatch(fetchAssetById(id));
      if (response?.success) {
        setAsset(response?.data);
      } else {
        console.error("Failed! Something went wrong.");
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };

  const fetchActivity = async () => {
    setLoader(true);
    try {
      const response = await dispatch(fetchAssetActivity(id));

      if (response?.success) {
        setActivity(response?.data);
      } else {
        console.error("Failed! Something went wrong.");
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const [open, setOpen] = useState(false);
  // SIDEBAR DRAWER TABBING PANEL
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      {/* START :: ASSETS VIEW HEADER */}
      <Box className="content-header view-lead-header asset-view-module-wrapper">
        <Typography variant="h2" component="h2" className="title-with-btn">
          <Button
            variant="outlined"
            className="action-btn back-btn"
            startIcon={<RiArrowLeftLine />}
            disableRipple
            onClick={() =>
              navigate("/assets", {
                state: {
                  childvalue: state.childvalue,
                  currentPage: state.currentPage,
                  searchAsset: state.searchAsset,
                  rowperPage: state.rowperPage,
                  filterApplied: state.filterApplied,
                  applyedFilter: state.applyedFilter,
                  asset_owner: state.asset_owner,
                },
              })
            }
          ></Button>
          Details for Asset Code {asset?.asset_code}
        </Typography>
        <Box variant="div" component="div" className="content-header-right">
          <Tooltip
            title={
              asset?.current_status !== "in_service"
                ? "You cannot create ticket for an asset that is not in service"
                : ""
            }
          >
            <WithPermission
              permission={updateAssetTicket}
              element={
                <Button
                  variant="contained"
                  size="large"
                  disabled={asset?.current_status !== "in_service"}
                  className="primary-btn btn add-btn"
                  endIcon={<RiAddCircleLine />}
                  disableRipple
                  onClick={() => {
                    navigate("/asset-tickets/create", {
                      state: {
                        childvalue: state.childvalue,
                        currentPage: state.currentPage,
                        searchAsset: state.searchAsset,
                        rowperPage: state.rowperPage,
                        filterApplied: state.filterApplied,
                        applyedFilter: state.applyedFilter,
                        asset_owner: state.asset_owner,
                      },
                    });
                  }}
                >
                  Create Asset Ticket
                </Button>
              }
            />
          </Tooltip>
          {role === "admin" && (
            <Button
              variant="contained"
              size="large"
              className="primary-btn btn add-btn"
              endIcon={<RiAlarmLine />}
              disableRipple
              onClick={() => setOpen(!open)}
            >
              Activity Log
            </Button>
          )}
        </Box>
      </Box>
      {/* END :: ASSETS VIEW HEADER */}

      {/* START :: ASSETS VIEW CONTENT */}
      {loader ? (
        <Loader />
      ) : asset?.length === 0 ? (
        <Box className="content-wrapper">
          <Typography
            variant="h4"
            component="h4"
            className="card-title text-center"
            sx={{ paddingBottom: "8px", margin: "16px 0" }}
          >
            Asset Not Found
          </Typography>
        </Box>
      ) : (
        <Box className="content-wrapper">
          <Stack spacing={3}>
            {role === "user" ? (
              <>
                <Box className="card-wrapper">
                  <Grid
                    container
                    rowSpacing={2}
                    columnSpacing={3}
                    className="view-info"
                  >
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography
                        variant="h3"
                        component="h3"
                        className="card-title"
                        sx={{ paddingBottom: "8px" }}
                      >
                        {asset?.asset_name}
                      </Typography>
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Typography variant="body1" component="label">
                        Asset Category
                      </Typography>
                      <Typography
                        variant="body1"
                        component="span"
                        className="value"
                      >
                        {asset?.asset_category?.name}
                      </Typography>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Typography variant="body1" component="label">
                        Asset Company
                      </Typography>
                      <Typography
                        variant="body1"
                        component="span"
                        className="value"
                      >
                        {asset?.asset_company?.company_name}
                      </Typography>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Typography variant="body1" component="label">
                        Asset Branch
                      </Typography>
                      <Typography
                        variant="body1"
                        component="span"
                        className="value"
                      >
                        {asset?.company_branches?.name}
                      </Typography>
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Divider className="divider" />
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography variant="body1" component="label">
                        Asset Description
                      </Typography>
                      <Typography
                        variant="body1"
                        component="span"
                        className="description"
                      >
                        {asset?.asset_description}
                      </Typography>
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Divider className="divider" />
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Typography variant="body1" component="label">
                        Current Status
                      </Typography>
                      <Typography
                        variant="body1"
                        component="span"
                        className="value"
                      >
                        {getStatusValueString(asset?.current_status)}
                      </Typography>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Typography variant="body1" component="label">
                        Assigned To
                      </Typography>
                      <Typography
                        variant="body1"
                        component="span"
                        className="value"
                      >
                        {asset?.assigned_user?.first_name +
                          " " +
                          asset?.assigned_user?.last_name}
                      </Typography>
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Divider className="divider" />
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Typography variant="body1" component="label">
                        Assigned Date
                      </Typography>
                      <Typography
                        variant="body1"
                        component="span"
                        className="value"
                      >
                        {asset?.assign_date
                          ? moment(asset?.assign_date).format("DD-MM-YYYY")
                          : "-"}
                      </Typography>
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Typography variant="body1" component="label">
                        Warranty
                      </Typography>
                      <Typography
                        variant="body1"
                        component="span"
                        className="value"
                      >
                        {asset?.warranty_period?.split("-")?.[0] == 0
                          ? ""
                          : asset?.warranty_period?.split("-")?.[0] + " Year "}
                        {asset?.warranty_period?.split("-")?.[1] == 0
                          ? ""
                          : asset?.warranty_period?.split("-")?.[1] + " Month"}
                      </Typography>
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Divider className="divider" />
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography variant="body1" component="label">
                        Warranty Description
                      </Typography>
                      <Typography
                        variant="body1"
                        component="span"
                        className="description"
                      >
                        {asset?.Warranty_description}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </>
            ) : (
              <>
                <Box className="card-wrapper">
                  <Grid container spacing={2} className="view-info">
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography
                        variant="h3"
                        component="h3"
                        className="card-title"
                        sx={{ paddingBottom: "8px" }}
                      >
                        {asset?.asset_name}
                      </Typography>
                    </Grid>

                    {asset?.asset_owner === "Client Asset" && (
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Typography variant="body1" component="label">
                          Client Name
                        </Typography>
                        <Typography
                          variant="body1"
                          component="span"
                          className="value"
                        >
                          {asset?.client_name}
                        </Typography>
                      </Grid>
                    )}
                    {asset?.asset_owner === "Client Asset" && (
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Typography variant="body1" component="label">
                          Project Name
                        </Typography>
                        <Typography
                          variant="body1"
                          component="span"
                          className="value"
                        >
                          {asset?.project_name}
                        </Typography>
                      </Grid>
                    )}

                    {asset?.asset_owner === "Company Asset" && (
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Divider className="divider" />
                      </Grid>
                    )}
                    {asset?.asset_owner === "Company Asset" && (
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Typography variant="body1" component="label">
                          Asset Category
                        </Typography>
                        <Typography
                          variant="body1"
                          component="span"
                          className="value"
                        >
                          {asset?.asset_category?.name}
                        </Typography>
                      </Grid>
                    )}

                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      sx={{
                        display: {
                          xs: "block",
                          sm: "none",
                          md: "none",
                          lg: "none",
                        },
                      }}
                    >
                      <Divider className="divider" />
                    </Grid>
                    {asset?.asset_owner === "Company Asset" && (
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Typography variant="body1" component="label">
                          Purchase Date
                        </Typography>
                        <Typography
                          variant="body1"
                          component="span"
                          className="value"
                        >
                          {asset?.purchase_date
                            ? moment(asset?.purchase_date).format("DD-MM-YYYY")
                            : "-"}
                        </Typography>
                      </Grid>
                    )}

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Divider className="divider" />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Typography variant="body1" component="label">
                        Asset Company
                      </Typography>
                      <Typography
                        variant="body1"
                        component="span"
                        className="value"
                      >
                        {asset?.asset_company?.company_name}
                      </Typography>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Typography variant="body1" component="label">
                        Asset Branch
                      </Typography>
                      <Typography
                        variant="body1"
                        component="span"
                        className="value"
                      >
                        {asset?.company_branches?.name}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      sx={{
                        display: {
                          xs: "block",
                          sm: "none",
                          md: "none",
                          lg: "none",
                        },
                      }}
                    >
                      <Divider className="divider" />
                    </Grid>
                    {/* {asset?.asset_owner === "Company Asset" && ( */}
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Divider className="divider" />
                    </Grid>
                    {/* )} */}

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Typography variant="body1" component="label">
                        Assigned To
                      </Typography>
                      <Typography
                        variant="body1"
                        component="span"
                        className="value"
                      >
                        {asset?.assigned_user?.first_name +
                          " " +
                          asset?.assigned_user?.last_name}
                      </Typography>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Typography variant="body1" component="label">
                        Assigned Date
                      </Typography>
                      <Typography
                        variant="body1"
                        component="span"
                        className="value"
                      >
                        {asset?.assign_date
                          ? moment(asset?.assign_date).format("DD-MM-YYYY")
                          : "-"}
                      </Typography>
                    </Grid>
                    {asset?.asset_owner === "Company Asset" && (
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Divider className="divider" />
                      </Grid>
                    )}
                    {asset?.asset_owner === "Company Asset" && (
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Typography variant="body1" component="label">
                          Purchase Price
                        </Typography>
                        <Typography
                          variant="body1"
                          component="span"
                          className="value"
                        >
                          {"₹" +
                            " " +
                            roundToTwoDecimal(asset?.purchase_price + "")}
                        </Typography>
                      </Grid>
                    )}
                    {asset?.asset_owner === "Company Asset" && (
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Typography variant="body1" component="label">
                          Current Value (Per Company Act)
                        </Typography>
                        <Typography
                          variant="body1"
                          component="span"
                          className="value"
                        >
                          {"₹" +
                            " " +
                            roundToTwoDecimal(
                              asset?.current_value_per_company_act.toString()
                            )}
                        </Typography>
                      </Grid>
                    )}
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Divider className="divider" />
                    </Grid>
                    {asset?.asset_owner === "Company Asset" && (
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Typography variant="body1" component="label">
                          Current Value (Per IT Act)
                        </Typography>
                        <Typography
                          variant="body1"
                          component="span"
                          className="value"
                        >
                          {"₹" +
                            " " +
                            roundToTwoDecimal(
                              asset?.current_value_per_it_act + ""
                            )}
                        </Typography>
                      </Grid>
                    )}
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Typography variant="body1" component="label">
                        Current Status
                      </Typography>
                      <Typography
                        variant="body1"
                        component="span"
                        className="value"
                      >
                        {getStatusValueString(asset?.current_status)}
                      </Typography>
                    </Grid>
                    {asset?.asset_owner === "Client Asset" && (
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Typography variant="body1" component="label">
                          Asset Returned
                        </Typography>
                        <Typography
                          variant="body1"
                          component="span"
                          className="value"
                        >
                          {asset?.is_returned ? "Yes" : "No"}
                        </Typography>
                      </Grid>
                    )}
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      sx={{
                        display: {
                          xs: "block",
                          sm: "none",
                          md: "none",
                          lg: "none",
                        },
                      }}
                    >
                      <Divider className="divider" />
                    </Grid>

                    {asset?.asset_owner === "Company Asset" && (
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Divider className="divider" />
                      </Grid>
                    )}

                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      sx={{
                        display: {
                          xs: "block",
                          sm: "none",
                          md: "none",
                          lg: "none",
                        },
                      }}
                    >
                      <Divider className="divider" />
                    </Grid>
                    {asset?.asset_owner === "Company Asset" && (
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Typography variant="body1" component="label">
                          Warranty
                        </Typography>
                        <Typography
                          variant="body1"
                          component="span"
                          className="value"
                        >
                          {asset?.warranty_period?.split("-")?.[0] == 0
                            ? ""
                            : asset?.warranty_period?.split("-")?.[0] +
                              " Year "}
                          {asset?.warranty_period?.split("-")?.[1] == 0
                            ? ""
                            : asset?.warranty_period?.split("-")?.[1] +
                              " Month"}
                        </Typography>
                      </Grid>
                    )}
                    {asset?.asset_owner === "Company Asset" && (
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Typography variant="body1" component="label">
                          Total Repair Cost
                        </Typography>
                        <Typography
                          variant="body1"
                          component="span"
                          className="value"
                        >
                          {asset?.repairing_cost === null || ""
                            ? "-"
                            : "₹" +
                              " " +
                              roundToTwoDecimal(
                                asset?.repairing_cost.toString()
                              )}
                        </Typography>
                      </Grid>
                    )}
                    {asset?.asset_owner === "Company Asset" && (
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Divider className="divider" />
                      </Grid>
                    )}
                    {asset?.asset_owner === "Company Asset" && (
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Typography variant="body1" component="label">
                          Vendor
                        </Typography>
                        <Typography
                          variant="body1"
                          component="span"
                          className="value"
                        >
                          {asset?.vendor?.company_name}
                        </Typography>
                      </Grid>
                    )}
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Divider className="divider" />
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Typography variant="body1" component="label">
                        Asset Description
                      </Typography>
                      <Typography
                        variant="body1"
                        component="span"
                        className="description"
                      >
                        {asset?.asset_description}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      sx={{
                        display: {
                          xs: "block",
                          sm: "block",
                          md: "none",
                          lg: "none",
                        },
                      }}
                    >
                      <Divider className="divider" />
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Divider className="divider" />
                    </Grid>
                    {asset?.asset_owner === "Company Asset" && (
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Typography variant="body1" component="label">
                          Warranty Description
                        </Typography>
                        <Typography
                          variant="body1"
                          component="span"
                          className="description"
                        >
                          {asset?.Warranty_description}
                        </Typography>
                      </Grid>
                    )}
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      sx={{
                        display: {
                          xs: "block",
                          sm: "none",
                          md: "none",
                          lg: "none",
                        },
                      }}
                    >
                      <Divider className="divider" />
                    </Grid>
                  </Grid>
                </Box>
              </>
            )}
            {asset?.asset_owner === "Client Asset" && asset?.is_returned && (
              <Box className="card-wrapper">
                <Grid container spacing={2} className="view-info">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography
                      variant="h3"
                      component="h3"
                      className="card-title"
                      sx={{ paddingBottom: "8px" }}
                    >
                      Asset Return Details
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography variant="body1" component="label">
                      Person Name
                    </Typography>
                    <Typography
                      variant="body1"
                      component="span"
                      className="value"
                    >
                      {asset?.name}
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography variant="body1" component="label">
                      Address
                    </Typography>
                    <Typography
                      variant="body1"
                      component="span"
                      className="value"
                    >
                      {asset?.address}
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Divider className="divider" />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography variant="body1" component="label">
                      Courier Company
                    </Typography>
                    <Typography
                      variant="body1"
                      component="span"
                      className="value"
                    >
                      {asset?.courier_company}
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography variant="body1" component="label">
                      Courier Number
                    </Typography>
                    <Typography
                      variant="body1"
                      component="span"
                      className="value"
                    >
                      {asset?.courier_number}
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Divider className="divider" />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography variant="body1" component="label">
                      Courier Cost
                    </Typography>
                    <Typography
                      variant="body1"
                      component="span"
                      className="value"
                    >
                      {asset?.cost}
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography variant="body1" component="label">
                      Date of Return
                    </Typography>
                    <Typography
                      variant="body1"
                      component="span"
                      className="value"
                    >
                      {asset?.return_date
                        ? moment(asset?.return_date).format("DD-MM-YYYY")
                        : "-"}
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Divider className="divider" />
                  </Grid>
                </Grid>
              </Box>
            )}
            <Box className="card-wrapper card-table-ui">
              <Grid container spacing={2} className="view-info">
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography
                    variant="h3"
                    component="h3"
                    className="card-title"
                  >
                    Asset Ticket
                  </Typography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TableContainer className="table-listing">
                    <Table
                      sx={{ minWidth: 650 }}
                      aria-label="table"
                      className="listing-table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Ticket Id</TableCell>
                          <TableCell align="left">Title</TableCell>
                          <TableCell align="left">Status</TableCell>
                          <TableCell align="left">Assign Date</TableCell>
                          <TableCell align="left">Repairing Cost (₹)</TableCell>
                          <TableCell align="left">Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      {loader ? (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={6}>
                              {" "}
                              <Loader />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ) : (
                        <TableBody>
                          {assetTickets?.length === 0 ? (
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                align="center"
                                sx={{ fontSize: "16px" }}
                                colSpan={6}
                              >
                                Asset ticket Not Found
                              </TableCell>
                            </TableRow>
                          ) : (
                            assetTickets?.map((item) => (
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                                key={item.id}
                              >
                                <TableCell align="left">{item?.id}</TableCell>
                                <TableCell align="left">
                                  {item?.title}
                                </TableCell>
                                <TableCell align="left">
                                  {item?.status}
                                </TableCell>
                                <TableCell align="left">
                                  {item?.request_date
                                    ? moment(item?.assign_date).format(
                                        "DD-MM-YYYY"
                                      )
                                    : "-"}
                                </TableCell>
                                <TableCell align="left">
                                  {item?.repairing_cost
                                    ? roundToTwoDecimal(
                                        item?.repairing_cost + ""
                                      )
                                    : "-"}
                                </TableCell>
                                <TableCell align="left">
                                  <Box className="action-btn-wrap">
                                    <WithPermission
                                      permission={viewAssetTicket}
                                      element={
                                        <Button
                                          variant="outlined"
                                          className="action-btn view-btn"
                                          startIcon={<RiEyeLine />}
                                          disableRipple
                                          onClick={() =>
                                            navigate(
                                              `/asset-tickets/view/${item?.id}`
                                            )
                                          }
                                        ></Button>
                                      }
                                    />
                                    {role === "user" ? (
                                      item?.status === "new" ||
                                      item?.status === "pending" ? (
                                        <WithPermission
                                          permission={updateAssetTicket}
                                          element={
                                            <Button
                                              variant="outlined"
                                              className="action-btn edit-btn"
                                              startIcon={<RiPencilLine />}
                                              disableRipple
                                              onClick={() =>
                                                navigate(
                                                  `/asset-tickets/edit/${item?.id}`
                                                )
                                              }
                                            ></Button>
                                          }
                                        />
                                      ) : null
                                    ) : (
                                      <WithPermission
                                        permission={updateAssetTicket}
                                        element={
                                          <Button
                                            variant="outlined"
                                            className="action-btn edit-btn"
                                            startIcon={<RiPencilLine />}
                                            disableRipple
                                            onClick={() =>
                                              navigate(
                                                `/asset-tickets/edit/${item?.id}`
                                              )
                                            }
                                          ></Button>
                                        }
                                      />
                                    )}
                                    <WithPermission
                                      permission={fullAssetTicket}
                                      element={
                                        <Button
                                          variant="outlined"
                                          className="action-btn dlt-btn"
                                          startIcon={<RiDeleteBinLine />}
                                          disableRipple
                                          onClick={() => {
                                            setAssetTicketId(item?.id);
                                            setAssetTicketsDelete(true);
                                          }}
                                        ></Button>
                                      }
                                    />
                                  </Box>
                                </TableCell>
                              </TableRow>
                            ))
                          )}
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </Box>
      )}
      {/* END :: ASSETS VIEW CONTENT */}

      {/*  START :: ASSET TICKET  ACTIVITY PANEL*/}
      <Drawer
        className="side-drawer view-activity-log"
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
      >
        <Box className="sidebar-tab-wrapper activity-log-wrapper">
          <Box className="sidebar-tab-wrapper">
            <Box className="close-btn" onClick={() => setOpen(false)}>
              <RiCloseLine />
            </Box>
            <Box className="sidebar-tab">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  className="tab-label-btn"
                  label="Activity Log"
                  {...a11yProps(0)}
                />
              </Tabs>
            </Box>
          </Box>
          <CustomTabPanel
            value={value}
            index={0}
            className="filter-content-wrapper"
          >
            <Stack spacing={2} sx={{ padding: "24px 14px 24px 24px" }}>
              {loader ? (
                <Box className="activity-log-list-wrapper">
                  <Loader />
                </Box>
              ) : (
                <Box className="activity-log-list-wrapper">
                  {activity?.length === 0 ? (
                    <Box className="activity-card-wrapper">
                      <Box className="activity-log-icon activity-call">
                        <RiBillLine />
                      </Box>
                      <Box className="activity-content-wrapper">
                        <Typography
                          variant="body1"
                          component="span"
                          className="date-time-drawer"
                          style={{
                            lineHeight: "19px !important",
                          }}
                        >
                          {asset?.createdAt
                            ? moment(asset?.createdAt).format("HH:mm")
                            : "-"}
                          <Typography
                            variant="body1"
                            component="span"
                            className="mobile-time-drawer"
                          >
                            |{" "}
                            {asset?.createdAt
                              ? moment(asset?.createdAt).format("MMM DD, YYYY")
                              : "-"}
                          </Typography>
                        </Typography>

                        <Box className="activity-content">
                          <Box className="activity-content-header">
                            <Typography variant="h6" component="h6">
                              {asset?.asset_name} asset created.
                            </Typography>
                            <Typography variant="body1" component="span">
                              {asset?.createdAt
                                ? moment(asset?.createdAt).format(
                                    "MMM DD, YYYY"
                                  )
                                : "-"}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <>
                      <>
                        {activity?.map((activities) => (
                          <Box
                            className="activity-card-wrapper"
                            key={activities.id}
                          >
                            <Box className="activity-log-icon activity-call">
                              <RiBillLine />
                            </Box>
                            <Box className="activity-content-wrapper">
                              <Typography
                                variant="body1"
                                component="span"
                                className="date-time-drawer"
                                style={{
                                  lineHeight: "19px !important",
                                }}
                              >
                                {asset?.createdAt
                                  ? moment(asset?.createdAt).format("HH:mm")
                                  : "-"}
                                <Typography
                                  variant="body1"
                                  component="span"
                                  className="mobile-time-drawer"
                                >
                                  |{" "}
                                  {asset?.createdAt
                                    ? moment(asset?.createdAt).format(
                                        "MMM DD, YYYY"
                                      )
                                    : "-"}
                                </Typography>
                              </Typography>
                              <Box className="activity-content">
                                <Box className="activity-content-header">
                                  <Typography variant="h6" component="h6">
                                    {activities.details}
                                  </Typography>
                                  <Typography variant="body1" component="span">
                                    {activities?.updatedAt
                                      ? moment(activities?.updatedAt).format(
                                          "MMM DD, YYYY"
                                        )
                                      : "-"}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        ))}
                      </>
                      <>
                        <Box className="activity-card-wrapper">
                          <Box className="activity-log-icon activity-call">
                            <RiBillLine />
                          </Box>
                          <Box className="activity-content-wrapper">
                            <Typography
                              variant="body1"
                              component="span"
                              className="date-time-drawer"
                              style={{
                                lineHeight: "19px !important",
                              }}
                            >
                              {asset?.createdAt
                                ? moment(asset?.createdAt).format("HH:mm")
                                : "-"}
                              <Typography
                                variant="body1"
                                component="span"
                                className="mobile-time-drawer"
                              >
                                |{" "}
                                {asset?.createdAt
                                  ? moment(asset?.createdAt).format(
                                      "MMM DD, YYYY"
                                    )
                                  : "-"}
                              </Typography>
                            </Typography>

                            <Box className="activity-content">
                              <Box className="activity-content-header">
                                <Typography variant="h6" component="h6">
                                  {asset?.asset_name} asset created.
                                </Typography>
                                <Typography variant="body1" component="span">
                                  {asset?.createdAt
                                    ? moment(asset?.createdAt).format(
                                        "MMM DD, YYYY"
                                      )
                                    : "-"}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </>
                    </>
                  )}
                </Box>
              )}
            </Stack>
          </CustomTabPanel>
        </Box>
      </Drawer>
      <AssetTicketsDeleteModal
        assetTicketsDelete={assetTicketsDelete}
        setAssetTicketsDelete={setAssetTicketsDelete}
        assetTicketId={assetTicketId}
        setAssetTicketId={setAssetTicketId}
        fetchData={fetchAssetTicketList}
      />
      {/* END :: ASSET TICKET  ACTIVITY PANEL*/}
    </>
  );
};

export default AssetsView;
