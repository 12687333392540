import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  announcements: [],
  status: "idle",
  error: null,
  totalAnnouncements: 0,
  showAllChecked: false,
  markedAsReadId: null,
};

const announcementsSlice = createSlice({
  name: "announcements",
  initialState,
  reducers: {
    fetchAnnouncementStart: (state) => {
      state.status = "loading";
    },
    fetchAnnouncementSuccess: (state, action) => {
      state.status = "succeeded";
      state.announcements = action.payload;
    },
    fetchAnnouncementFailure: (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
    },
    setTotalAnnouncements: (state, action) => {
      state.totalAnnouncements = action.payload;
    },
    setShowAllCheckedTrue: (state, action) => {
      state.showAllChecked = true;
    },
    setShowAllCheckedFalse: (state, action) => {
      state.showAllChecked = false;
    },
    setMarkedAsReadId: (state, action) => {
      state.markedAsReadId = action.payload;
    },
    unsetMarkedAsReadId: (state, action) => {
      state.markedAsReadId = null;
    },
  },
});

export const {
  fetchAnnouncementStart,
  fetchAnnouncementSuccess,
  fetchAnnouncementFailure,
  setTotalAnnouncements,
  setShowAllCheckedTrue,
  setShowAllCheckedFalse,
  setMarkedAsReadId,
  unsetMarkedAsReadId,
} = announcementsSlice.actions;

export default announcementsSlice.reducer;

export const fetchAnnouncements = (params) => async (dispatch) => {};
