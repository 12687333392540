import { Box, Fade, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import Button from "@mui/material/Button";
import axios from "axios";
import { useDispatch } from "react-redux";
import { callApi } from "./annoucementApi";
import "../../styles/announcement.scss";
import {
  setShowAllCheckedTrue,
  setTotalAnnouncements,
} from "../../store/slices/announcementSlice";
const AnnouncementModal = ({
  openReadAllModal,
  setopenReadAllModal,
  userId,
  setPage,
  setnotifications,
  settotalNotifications,
  setmarkAllFlag,
  selectedTab,
  setHasMore,
  announcementIds,
  setReadAnnouncements,
}) => {
  const dispatch = useDispatch();
  const markAllAsRead = async () => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_LOCAL_CENTRAL_URL}/announcement/${userId}/read`,
        {
          announcementIds: announcementIds,
          is_all_read: true,
          moduleId: 3,
        }
      );
      setReadAnnouncements((p) => !p);
      setopenReadAllModal(false);
      const response = await callApi({
        method: "get",
        url: `${process.env.REACT_APP_LOCAL_CENTRAL_URL}/announcement/module/3`,
      });

      dispatch(setTotalAnnouncements(response?.data?.unreadCount));
      dispatch(setShowAllCheckedTrue());
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openReadAllModal}
        closeAfterTransition
      >
        <Fade in={openReadAllModal}>
          <Box className="modal-wrapper" style={{ padding: "24px" }}>
            <Box className="modal-body">
              <Box variant="div" component="div" className="title">
                <Typography variant="h3" component="h3">
                  Are you sure to mark all announcements as Read?
                </Typography>
                <Typography variant="body1" component="p">
                  This action can’t be undone.
                </Typography>
              </Box>
              <Box variant="div" component="div" className="body"></Box>
              <Box variant="div" component="div" className="footer">
                <Box variant="div" component="div" className="btn-group">
                  <Button
                    variant="contained"
                    size="large"
                    className="primary-btn btn"
                    disableRipple
                    onClick={markAllAsRead}
                  >
                    Yes
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    className="secondary-btn btn"
                    disableRipple
                    onClick={() => {
                      setopenReadAllModal(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default AnnouncementModal;
