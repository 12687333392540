import React, { useEffect, useState, useCallback } from "react";
import { TbArrowsSort } from "react-icons/tb";
import { RiDeleteBinLine, RiEyeLine, RiPencilLine } from "react-icons/ri";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Tabs,
  Tab,
  Badge,
  Typography,
  Tooltip,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import PurchaseRequestDeleteModal from "../../../components/common/PurchaseRequestDeleteModal";
import { useDispatch } from "react-redux";
import { fetchPurchaseRequest } from "../../../store/slices/purchaseRequestSlice";
import Loader from "../../../components/common/Loader";
import moment from "moment";
import {
  fullRequest,
  updateRequest,
  viewRequest,
} from "../../../utils/permissions";
import WithPermission from "../../../components/common/withPermission";
import { styled } from "@mui/material/styles";
import { CustomTablePaginationToolbar } from "../../../components/common/Pagination";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    position: "unset",
    transform: "unset",
    marginLeft: "4px",
    lineHeight: "16px",
    fontSize: "14px",
    padding: "0 4px",
    background: "#7cb900",
    fontWeight: "700",
    color: "#fff",
    zIndex: 0,
    marginTop: "-4px",
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const PurchaseRequestList = ({
  searchRequest,
  onPageChange,
  onValueChange,
  currentPage,
  value,
  rowperPage,
  onChangerowsPerPage,
  asset_company,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  let { state } = location;
  if (state === null) {
    state = {
      value: 0,
      currentPage: 1,
      rowperPage: 10,
      search: "",
      asset_company: "0",
    };
  }
  const [loader, setLoader] = useState(true);
  const [count, setCount] = useState(0);
  const [requests, setRequests] = useState([]);
  const [requestId, setRequestId] = useState("");
  const [order, setOrder] = useState("DESC");
  const [purchaseRequestDelete, setPurchaseRequestDelete] = useState(false);

  const handleChange = (event, newValue) => {
    onValueChange(newValue);
    onPageChange(1);
    navigate("/purchase-request", {
      state: {
        value: value,
        currentPage: currentPage,
        searchRequest: searchRequest,
        rowperPage: rowperPage,
      },
    });
  };

  const handleChangeRowsPerPage = (event) => {
    onChangerowsPerPage(parseInt(event.target.value, 10));
    onPageChange(1);
  };

  const tabLabel = (role, modules) => {
    return (
      <Box>
        <Typography variant="body1" component="span" className="user-name">
          {role}
        </Typography>{" "}
        {modules !== 0 ? (
          <StyledBadge
            badgeContent={modules}
            color="success"
            max={9999}
          ></StyledBadge>
        ) : null}
      </Box>
    );
  };

  const getTabValueString = (tabValue) => {
    switch (tabValue) {
      case 0:
        return "requested";
      case 1:
        return "under_process";
      case 2:
        return "procured";
      case 3:
        return "rejected";
      default:
        return "requested";
    }
  };

  const handlePageChange = (event, newPage) => {
    onPageChange(newPage);
  };

  const fetchData = useCallback(async () => {
    setLoader(true);
    try {
      const response = await dispatch(
        fetchPurchaseRequest(
          currentPage,
          searchRequest,
          getTabValueString(value),
          order,
          rowperPage,
          asset_company
        )
      );
      if (response?.success) {
        setRequests(response?.data);
        setCount(response?.data?.totalCount);
      } else {
        console.error("Failed! Something went wrong.");
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  }, [
    dispatch,
    currentPage,
    searchRequest,
    value,
    order,
    rowperPage,
    asset_company,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <Box variant="div" component="div" className="tab-wrapper user-listing">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            className="tab-area"
          >
            <Tab
              label={tabLabel("Requested", requests?.requestedCount)}
              {...a11yProps(0)}
            />
            <Tab
              label={tabLabel("Under Process", requests?.underProcessCount)}
              {...a11yProps(1)}
            />
            <Tab
              label={tabLabel("Procured", requests?.procuredCount)}
              {...a11yProps(2)}
            />
            <Tab
              label={tabLabel("Rejected", requests?.rejectedCount)}
              {...a11yProps(3)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel
          value={value}
          index={value}
          className="user-listing-tables"
        >
          <TableContainer className="table-listing">
            <Table
              sx={{ minWidth: 650 }}
              aria-label="table"
              className="listing-table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    align="left"
                    style={{ cursor: "pointer" }}
                    onClick={() => setOrder(order === "DESC" ? "ASC" : "DESC")}
                  >
                    <TbArrowsSort style={{ stroke: "rgb(0 0 0 / 52%)" }} />{" "}
                    <span>Req ID</span>
                  </TableCell>
                  <TableCell align="left">Asset Category</TableCell>
                  <TableCell align="left">Title</TableCell>
                  <TableCell align="left">Asset Company</TableCell>
                  <TableCell align="left">Created By</TableCell>
                  <TableCell align="left">Request Date</TableCell>
                  <TableCell align="left">Updated Date</TableCell>
                  <TableCell align="left">Actions</TableCell>
                </TableRow>
              </TableHead>
              {loader ? (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={7}>
                      {" "}
                      <Loader />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {requests?.results?.length === 0 ? (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        align="center"
                        sx={{ fontSize: "16px" }}
                        colSpan={7}
                      >
                        Purchase Request Not Found
                      </TableCell>
                    </TableRow>
                  ) : (
                    requests?.results?.map((item) => (
                      <TableRow
                        key={item.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="left">{item?.id}</TableCell>
                        <TableCell align="left">
                          {item?.asset_category?.name}
                        </TableCell>
                        <TableCell align="left">
                          <Tooltip
                            title={
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "13px",
                                }}
                              >
                                {item?.title}
                              </div>
                            }
                            arrow
                          >
                            {" "}
                            {item?.title?.length < 22
                              ? item?.title
                              : item?.title.slice(0, 18) + "..."}
                          </Tooltip>
                        </TableCell>
                        <TableCell align="left">
                          <Tooltip
                            title={
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "13px",
                                }}
                              >
                                {item?.asset_company?.company_name}
                              </div>
                            }
                            arrow
                          >
                            {" "}
                            {item?.asset_company?.company_name.length < 20
                              ? item?.asset_company?.company_name
                              : item?.asset_company?.company_name.slice(0, 16) +
                                "..."}
                          </Tooltip>
                        </TableCell>
                        <TableCell align="left">
                          {item?.createdBy_user?.first_name +
                            " " +
                            item?.createdBy_user?.last_name}
                        </TableCell>
                        <TableCell align="left">
                          {item?.request_date
                            ? moment(item?.request_date).format("DD-MM-YYYY")
                            : "-"}
                        </TableCell>
                        <TableCell align="left">
                          {item?.updatedAt
                            ? moment(item?.updatedAt).format("DD-MM-YYYY")
                            : "-"}
                        </TableCell>
                        <TableCell align="left">
                          <Box className="action-btn-wrap">
                            <WithPermission
                              permission={viewRequest}
                              element={
                                <Button
                                  variant="outlined"
                                  className="action-btn view-btn"
                                  startIcon={<RiEyeLine />}
                                  disableRipple
                                  onClick={() =>
                                    navigate(
                                      `/purchase-request/view/${item?.id}`,
                                      {
                                        state: {
                                          value: value,
                                          currentPage: currentPage,
                                          searchRequest: searchRequest,
                                          rowperPage: rowperPage,
                                          asset_company: asset_company,
                                        },
                                      }
                                    )
                                  }
                                ></Button>
                              }
                            />
                            <WithPermission
                              permission={updateRequest}
                              element={
                                <Button
                                  variant="outlined"
                                  className="action-btn edit-btn"
                                  startIcon={<RiPencilLine />}
                                  disableRipple
                                  onClick={() =>
                                    navigate(
                                      `/purchase-request/edit/${item?.id}`,
                                      {
                                        state: {
                                          value: value,
                                          currentPage: currentPage,
                                          searchRequest: searchRequest,
                                          rowperPage: rowperPage,
                                          asset_company: asset_company,
                                        },
                                      }
                                    )
                                  }
                                ></Button>
                              }
                            />
                            <WithPermission
                              permission={fullRequest}
                              element={
                                <Button
                                  variant="outlined"
                                  className="action-btn dlt-btn"
                                  startIcon={<RiDeleteBinLine />}
                                  disableRipple
                                  onClick={() => {
                                    setRequestId(item?.id);
                                    setPurchaseRequestDelete(true);
                                  }}
                                ></Button>
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </CustomTabPanel>
      </Box>
      <CustomTablePaginationToolbar
        count={count}
        page={currentPage}
        rowsPerPage={rowperPage}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <PurchaseRequestDeleteModal
        purchaseRequestDelete={purchaseRequestDelete}
        setPurchaseRequestDelete={setPurchaseRequestDelete}
        requestId={requestId}
        setRequestId={setRequestId}
        fetchData={fetchData}
      />
    </>
  );
};

export default PurchaseRequestList;
