import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Stack, Tab, Tabs, Typography } from "@mui/material";
import {
  RiArrowRightLine,
  RiCloseLine,
  RiCoupon3Line,
  RiDeleteBinLine,
  RiSettings4Line,
} from "react-icons/ri";
import CampaignIcon from "@mui/icons-material/Campaign";
import { roundToTwoDecimal } from "../../../utils/helper";
import Marquee from "react-marquee-slider";
import axios from "axios";
import Cookies from "js-cookie";
import "../../../styles/announcement.scss";
import { callApi } from "../../../components/common/annoucementApi";
import OpenTicketInfoList from "./OpenTicketInfoList";
import HightestPurchaseVendorList from "./HightestPurchaseVendorList";
import { fetchDashboardDetails } from "../../../store/slices/dashboardSlice";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import AnnouncementModal2 from "../../../components/common/announcementModel2";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Dashboard = () => {
  // START :: TAB WISE LISTING CHART VALUATION
  const [value, setValue] = useState(0);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dashboardData, setDashboardData] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const location = useLocation();
  const { state } = location;
  const categoryCounts = categoryList.map(
    (item) => item?.category_wise_asset_count
  );

  const Totalsumofassetcategory = categoryList.map(
    (item) => item?.total_purchase_price
  );
  const categoryLabels = categoryList.map((item) => item?.asset_category?.name);

  const userId = Cookies.get(process.env.REACT_APP_USER_ID_COOKIE);
  const [announcementList, setAnnouncementList] = useState([]);
  async function fetchAllAnnouncements() {
    try {
      const response = await callApi({
        method: "get",
        url: `${process.env.REACT_APP_LOCAL_CENTRAL_URL}/announcement/module/3`,
      });

      setAnnouncementList(response?.data?.marquee_announcements);
    } catch (error) {
      console.error("Failed to fetch announcements:", error);
    }
  }

  useEffect(() => {
    fetchAllAnnouncements();
  }, []);

  const announcements = announcementList?.map((an) => ({
    id: an?.id,
    title: an?.title,
    description: an?.description,
    author: `${an?.authorInfo?.first_name} ${an?.authorInfo?.last_name}`,
    date: an?.createdAt,
  }));

  const [isPaused, setIsPaused] = useState(false);
  const [viewAnnouncementOpen, setViewAnnouncementOpen] = useState(false);
  const [announcement, setAnnouncement] = useState("");

  const lineChartOptions = {
    series: [
      {
        name: "",
        data: Totalsumofassetcategory,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "bar",
        zoom: {
          type: "x",
          enabled: true,
          autoScaleYaxis: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
          colors: {
            ranges: [
              {
                from: 0,
                to: Math.max(...Totalsumofassetcategory) + 1,
                color: "#7cb900", // Set the bar color here
              },
            ],
          },
        },
      },
      xaxis: {
        categories: categoryLabels,
      },
      tooltip: {
        shared: true,
        intersect: false,
        marker: {
          fillColors: ['#7cb900'], // Set the marker color to green here
        },
        y: {
          formatter: function (y, { dataPointIndex }) {
            if (typeof y !== "undefined") {
              return (
                "<ul class='tooltipul'>" +
                "<li><span>Assets Value (₹):&nbsp;</span>" +
                roundToTwoDecimal(y + "") +
                "</li>" +
                "</ul>"
              );
            }
            return y;
          }
        },
      },
    },
  };

  const valuationChartOptions = {
    series: [
      {
        name: "Assets",
        data: categoryCounts,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "bar",
        zoom: {
          type: "x",
          enabled: true,
          autoScaleYaxis: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
          colors: {
            ranges: [
              {
                from: 0,
                to: Math.max(...categoryCounts) + 1,
                color: "#7cb900", // Set the bar color here
              },
            ],
          },
        },
      },
      xaxis: {
        categories: categoryLabels,
      },
    },
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabLabel = (role, modules) => {
    return (
      <Box>
        <span className="user-name">{role}</span>
      </Box>
    );
  };
  // END :: TAB WISE LISTING CHART VALUATION

  const fetchData = async () => {
    setLoader(true);
    try {
      const response = await dispatch(fetchDashboardDetails());
      if (response?.success) {
        setDashboardData(response?.data);
        setCategoryList(response?.data?.categoryList);
      } else {
        console.error("Failed! Something went wrong.");
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {announcements?.length > 0 && (
        <Box className="announcement-contents">
          <Box
            className="announcement-marque"
            onMouseEnter={() => setIsPaused(true)}
            onMouseLeave={() => setIsPaused(false)}
          >
            <Box
              sx={{
                display: "inline-block",
                paddingRight: "5px",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CampaignIcon
                sx={{
                  color: "rgb(124, 185, 0)",
                  fontSize: { xs: "24px", sm: "32px" },
                }}
              />
            </Box>
            <Box sx={{ flex: 1, minWidth: 0 }}>
            <Marquee
              velocity={isPaused ? 0 : Math.min(25, 300 / announcements?.length)}
              direction="rtl"
              resetAfterTries={100}
            >
              {announcements?.map((announcement, index) => (
                <Box key={index} className="marque-list">
                  {/* Bullet point at the start of each announcement */}
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "bold !important",
                      color: "rgb(35, 35, 35) !important",
                      paddingRight: "10px", // Space after the bullet
                    }}
                  >
                    •
                  </Typography>
                  {/* Announcement text */}
                  <Typography
                    variant="body1"
                    className="marque-text"
                    onClick={async () => {
                      try {
                        setViewAnnouncementOpen(true);
                        setAnnouncement({
                          name: announcement?.title,
                          date: announcement?.date,
                          content: announcement?.description,
                          authorName: announcement?.author,
                        });
                        setIsPaused(false);

                        await axios.patch(
                          `${process.env.REACT_APP_LOCAL_CENTRAL_URL}/announcement/${userId}/read`,
                          {
                            announcement_id: announcement?.id,
                            is_all_read: false,
                          }
                        );
                      } catch (error) {
                        console.error(
                          "Failed to mark announcement as read:",
                          error
                        );
                      }
                    }}
                  >
                    {announcement?.title}
                  </Typography>
                </Box>
              ))}
            </Marquee>
          </Box>
          </Box>

          <AnnouncementModal2
            announcement={announcement}
            viewAnnouncementOpen={viewAnnouncementOpen}
            setViewAnnouncementOpen={setViewAnnouncementOpen}
          ></AnnouncementModal2>
        </Box>
      )}
      {/*  START :: DASHBOARD TITLE */}
      <Box className="content-header project-list-header">
        <Typography variant="h2" component="h2">
          Dashboard
        </Typography>
      </Box>
      {/* END :: DASHBOARD TITLE */}

      <Box className="content-wrapper">
        <Grid container spacing={3}>
          {/* START :: DASHBOARD COUNT */}
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <Box className="card-wrapper">
              <Box className="dashboard-count-wrapper">
                <Stack spacing={2}>
                  <Typography variant="body1" component="span">
                    In Service
                  </Typography>
                  <Typography variant="h2" component="h2">
                    {dashboardData?.totalInService}
                  </Typography>
                  <Button
                    variant="contained"
                    size="large"
                    className="dashboard-btn"
                    endIcon={<RiArrowRightLine />}
                    disableRipple
                    onClick={() => navigate(`/assets`, { state: state })}
                  >
                    View More
                  </Button>
                </Stack>
                <Box className="dashboard-count-icon in-service">
                  <RiSettings4Line />
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <Box className="card-wrapper">
              <Box className="dashboard-count-wrapper">
                <Stack spacing={2}>
                  <Typography variant="body1" component="span">
                    Out Of Service
                  </Typography>
                  <Typography variant="h2" component="h2">
                    {dashboardData?.totalOutOfService}
                  </Typography>
                  <Button
                    variant="contained"
                    size="large"
                    className="dashboard-btn"
                    endIcon={<RiArrowRightLine />}
                    disableRipple
                    onClick={() => navigate(`/assets`, { state: 2 })}
                  >
                    View More
                  </Button>
                </Stack>
                <Box className="dashboard-count-icon out-of-service">
                  <RiDeleteBinLine />
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <Box className="card-wrapper">
              <Box className="dashboard-count-wrapper">
                <Stack spacing={2}>
                  <Typography variant="body1" component="span">
                    Open Ticket
                  </Typography>
                  <Typography variant="h2" component="h2">
                    {dashboardData?.totalOpenTicket}
                  </Typography>
                  <Button
                    variant="contained"
                    size="large"
                    className="dashboard-btn"
                    endIcon={<RiArrowRightLine />}
                    disableRipple
                    onClick={() => navigate(`/asset-tickets`)}
                  >
                    View More
                  </Button>
                </Stack>
                <Box className="dashboard-count-icon open-ticket">
                  <RiCoupon3Line />
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <Box className="card-wrapper">
              <Box className="dashboard-count-wrapper">
                <Stack spacing={2}>
                  <Typography variant="body1" component="span">
                    Rejected Ticket
                  </Typography>
                  <Typography variant="h2" component="h2">
                    {dashboardData?.totalRejectedTicket}
                  </Typography>
                  <Button
                    variant="contained"
                    size="large"
                    className="dashboard-btn"
                    endIcon={<RiArrowRightLine />}
                    disableRipple
                    onClick={() => navigate(`/asset-tickets`, { state: 3 })}
                  >
                    View More
                  </Button>
                </Stack>
                <Box className="dashboard-count-icon close-ticket">
                  <RiCloseLine />
                </Box>
              </Box>
            </Box>
          </Grid>
          {/* END :: DASHBOARD COUNT */}

          {/* START :: CHART TABBING FOR CATEGORY WISE VALUATION */}
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box
              className="card-wrapper"
              sx={{ padding: "0 !important", height: "100%" }}
            >
              <Box className="tab-wrapper user-listing">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="tab-area"
                  >
                    <Tab
                      label={tabLabel("Category Wise Count Chart")}
                      {...a11yProps(0)}
                    />
                    <Tab
                      label={tabLabel("Category wise Valution Chart")}
                      {...a11yProps(1)}
                    />
                  </Tabs>
                </Box>
                <CustomTabPanel
                  value={value}
                  index={0}
                  className="user-listing-tables p-24"
                >
                  <Stack spacing={2}>
                    <Box className="card-title chart-list-title">
                      <Typography variant="h3" component="h3">
                        Category Wise Count
                      </Typography>
                    </Box>
                    <Box
                      className="chart-container custom-tooltip"
                      style={{ width: "100%", height: "100%" }}
                    >
                      <ReactApexChart
                        options={valuationChartOptions.options}
                        series={valuationChartOptions.series}
                        type="bar"
                      />
                    </Box>
                  </Stack>
                </CustomTabPanel>
                <CustomTabPanel
                  value={value}
                  index={1}
                  className="user-listing-tables p-24"
                >
                  <Stack spacing={2}>
                    <Box className="card-title chart-list-title">
                      <Typography variant="h3" component="h3">
                        Category Wise Valuation
                      </Typography>
                    </Box>
                    <Box
                      className="chart-container custom-tooltip"
                      style={{ width: "100%", height: "100%" }}
                    >
                      <ReactApexChart
                        options={lineChartOptions.options}
                        series={lineChartOptions.series}
                        type="bar"
                      />
                    </Box>
                  </Stack>
                </CustomTabPanel>
                {/* <CustomTabPanel
                  value={value}
                  index={1}
                  className="user-listing-tables p-24"
                >
                  <Stack spacing={2}>
                    <Box className="card-title chart-list-title">
                      <Typography variant="h3" component="h3">
                        Category Wise Line
                      </Typography>
                    </Box>
                    <Box
                      className="chart-container"
                      style={{ width: "100%", height: "100%" }}
                    >
                      <ReactApexChart
                        options={lineChartOptions.options}
                        series={lineChartOptions.series}
                        type="line"
                      />
                    </Box>
                  </Stack>
                </CustomTabPanel> */}
              </Box>
            </Box>
          </Grid>
          {/* END :: CHART TABBING FOR CATEGORY WISE VALUATION */}

          {/* START :: OPEN TICKET INFORMATION TABLE LIST */}
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box
              className="card-wrapper open-ticket-table-wrapper"
              sx={{ padding: "0 !important", height: "100%" }}
            >
              <Stack>
                <Typography
                  variant="h3"
                  component="h3"
                  sx={{ padding: "24px 24px 0 24px !important" }}
                >
                  Open Ticket Information
                </Typography>

                <OpenTicketInfoList
                  openTicketInfo={dashboardData?.openTicketList}
                  loader={loader}
                />
              </Stack>
            </Box>
          </Grid>
          {/* END :: OPEN TICKET INFORMATION TABLE LIST */}

          {/* START :: HIGHEST PURHCASEING VENDOR DETAIL TABLE LIST */}
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box className="card-wrapper" sx={{ padding: "0 !important" }}>
              <Stack>
                <Typography
                  variant="h3"
                  component="h3"
                  sx={{ padding: "24px 24px 0 24px !important" }}
                >
                  Highest Purchasing Vendor Detail
                </Typography>

                <HightestPurchaseVendorList
                  hightestPurchaseVendorList={
                    dashboardData?.highestPurchasingVendorDetail
                  }
                  loader={loader}
                />
              </Stack>
            </Box>
          </Grid>
          {/* END :: HIGHEST PURHCASEING VENDOR DETAIL TABLE LIST */}
        </Grid>
      </Box>
    </>
  );
};

export default Dashboard;
