import { createSlice } from "@reduxjs/toolkit";
import api from "../../components/common/commonfunctionforapi";
import { setFeedback } from "./feedbackSlice";

const initialState = {
  companyBranch: [],
  isLoading: false,
  success: false,
  error: null,
};

const compnayBranchSlice = createSlice({
  name: "companyBranch",
  initialState,
  reducers: {
    fetchCompanyBranchStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    fetchCompanyBranchSuccess: (state, action) => {
      state.isLoading = false;
      state.success = true;
      state.companyBranch = action.payload;
    },
    fetchCompanyBranchFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
    fetchCompanyBranchByIdStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    fetchCompanyBranchByIdSuccess: (state, action) => {
      state.isLoading = false;
      state.success = true;
      state.companyBranch = action.payload;
    },
    fetchCompanyBranchByIdFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
    createCompanyBranchStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    createCompanyBranchSuccess: (state, action) => {
      state.isLoading = false;
      state.success = true;
    },
    createCompanyBranchFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
    editCompanyBranchStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    editCompanyBranchSuccess: (state, action) => {
      state.isLoading = false;
      state.success = true;
      const companyIndex = state.companyBranch.findIndex(
        (company) => company.id === action.payload.id
      );
      if (companyIndex !== -1) {
        state.companyBranch[companyIndex] = action.payload;
      }
    },
    editCompanyBranchFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
    deleteCompanyBranchStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    deleteCompanyBranchSuccess: (state, action) => {
      state.isLoading = false;
      state.success = true;
    },
    deleteCompanyBranchFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchCompanyBranchStart,
  fetchCompanyBranchSuccess,
  fetchCompanyBranchFailure,
  editCompanyBranchStart,
  editCompanyBranchSuccess,
  editCompanyBranchFailure,
  fetchCompanyBranchByIdStart,
  fetchCompanyBranchByIdSuccess,
  fetchCompanyBranchByIdFailure,
  createCompanyBranchStart,
  createCompanyBranchSuccess,
  createCompanyBranchFailure,
  deleteCompanyBranchStart,
  deleteCompanyBranchSuccess,
  deleteCompanyBranchFailure,
} = compnayBranchSlice.actions;
export default compnayBranchSlice.reducer;

export const fetchCompanyBranch =
  (pagination = true, page = 1, search, rowperPage, company_id = 0) =>
  async (dispatch) => {
    try {
      dispatch(fetchCompanyBranchStart());

      let url = `/company-branches?page=${page}&rows=${rowperPage}&pagination=${pagination}`;
      if (search) {
        url += `&search=${search}`;
      }
      if (company_id) {
        url += `&company_id=${company_id}`;
      }
      const response = await api.get(url);
      const companyBranch = response;
      dispatch(fetchCompanyBranchSuccess(companyBranch?.data));
      return { success: true, data: companyBranch?.data };
    } catch (error) {
      dispatch(fetchCompanyBranchFailure(error.message));
      dispatch(
        setFeedback({
          status: "fail",
          message: error?.response?.data?.message,
          code: error?.response?.status,
        })
      );
    }
  };
export const fetchCompanyProjects = () => async (dispatch) => {
  try {
    dispatch(fetchCompanyBranchStart());

    let url = `/asset/projectsdetails`;

    const response = await api.get(url);
    const projects = response;
    dispatch(fetchCompanyBranchSuccess(projects?.data));
    return { success: true, data: projects?.data };
  } catch (error) {
    dispatch(fetchCompanyBranchFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error?.response?.data?.message,
        code: error?.response?.status,
      })
    );
  }
};

export const fetchCompanyBranchById = (branchId) => async (dispatch) => {
  try {
    dispatch(fetchCompanyBranchByIdStart());

    const response = await api.get(`/company-branches/${branchId}`);

    const company = response?.data;
    dispatch(fetchCompanyBranchByIdSuccess(company));
    return { success: true, message: response?.message, data: company };
  } catch (error) {
    dispatch(fetchCompanyBranchByIdFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error?.response?.data?.message,
        code: error?.response?.status,
      })
    );
  }
};

export const createCompanyBranch = (categoryData) => async (dispatch) => {
  try {
    dispatch(createCompanyBranchStart());

    const response = await api.post(`/company-branches`, categoryData);

    const category = response;
    dispatch(createCompanyBranchSuccess(category?.data));
    return { success: true, message: response?.message };
  } catch (error) {
    dispatch(createCompanyBranchFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error?.response?.data?.message,
        code: error?.response?.status,
      })
    );
    return { success: false, error: error?.response?.data?.message };
  }
};

export const editCompanyBranch = (companyData, id) => async (dispatch) => {
  try {
    dispatch(editCompanyBranchStart());

    const response = await api.patch(`/company-branches/${id}`, companyData);

    const company = response?.data;
    return { success: true, message: company?.message };
  } catch (error) {
    dispatch(editCompanyBranchFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error?.response?.data?.message,
        code: error?.response?.status,
      })
    );
    return { success: false, error: error?.response?.data?.message };
  }
};

export const deleteCompanyBranch = (companyId) => async (dispatch) => {
  try {
    dispatch(deleteCompanyBranchStart());

    const response = await api.delete(`/company-branches/${companyId}`);

    dispatch(deleteCompanyBranchSuccess(companyId));
    return { success: true, message: response?.message };
  } catch (error) {
    dispatch(deleteCompanyBranchFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error?.response?.data?.message,
        code: error?.response?.status,
      })
    );
    return { success: false, error: error?.response?.data?.message };
  }
};
