import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  Grid,
  Pagination,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { fetchReportDetailsList } from "../../../store/slices/reportSlice";
import Loader from "../../../components/common/Loader";
import moment from "moment";
import ExportToPdf from "../../../components/common/exportToPdfModal";
import { assetStatusList, formatDefaultPrice } from "../../../utils/helper";
import { fetchTicketsDetailsList } from "../../../store/slices/reportSlice";
import { roundToTwoDecimal } from "../../../utils/helper";
import ReactApexChart from "react-apexcharts";
import { CustomTablePaginationToolbar } from "../../../components/common/Pagination";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 0 }}>{children}</Box>}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AssetTicketDetailsList = ({
  applyedFilter,
  exportCancel,
  isExcel,
  onCloseExport,
}) => {
  // START :: TAB WISE LISTING FOR ASSETS TICKET
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [reportData, setReportData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [currentPage, setcurrentPage] = useState(1);
  const [count, setCount] = useState(1);
  const [totalMaintainance, setTotalMantainance] = useState(0);
  const [chartData, setChartData] = useState([]);
  const [rowperPage, setRowsPerPage] = useState(10);
  const handlePageChange = (event, newPage) => {
    setcurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setcurrentPage(1);
  };

  const fetchData = async () => {
    setLoader(true);
    try {
      const response = await dispatch(
        fetchTicketsDetailsList(
          "completed",
          currentPage,
          applyedFilter,
          rowperPage
        )
      );
      if (response?.success) {
        setReportData(response?.data?.results);
        setTotalMantainance(response?.data?.TotalSum);
        setChartData(response?.data?.monthlySumArray);
        setCount(response?.data?.totalCount);
      } else {
        console.error("Failed! Something went wrong.");
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [value, currentPage, applyedFilter, rowperPage]);

  const allTabColumn = [
    { title: "Ticket #", dataKey: "id" },
    {
      title: "Asset",
      dataKey: "assetId",
      formatter: (value) => {
        return `${value?.asset_code} - ${value?.asset_name}`;
      },
    },
    { title: "Title", dataKey: "title" },
    { title: "Vendor", dataKey: "vendor?.company_name" },
    {
      title: "Requested Date",
      dataKey: "request_date",
      formatter: (value) => {
        return value ? moment(value).format("DD-MM-YYYY") : "-";
      },
    },
    {
      title: "Completion Date",
      dataKey: "resolution_date",
      formatter: (value) => {
        return value ? moment(value).format("DD-MM-YYYY") : "-";
      },
    },
    {
      title: "Maintainance (₹)",
      dataKey: "repairing_cost",
      formatter: (priceToFormat) => {
        return roundToTwoDecimal(priceToFormat + "");
      },
    },
  ];

  const getkeyData = (obj, dynamicKey) => {
    const keys = dynamicKey.split(/(\?\.|\.)/); // Split on ?. or .
    const value = keys.reduce((acc, key) => {
      const keyData = keys.shift();
      if (key === "?.") {
        return acc?.[keyData] ?? "-";
      } else if (key === ".") {
        return acc[keyData] ?? "-";
      } else {
        return acc[key] ?? "-";
      }
    }, obj);
    return value;
  };

  const state = {
    series: [
      {
        name: "Value",
        data: chartData.map((value) => value.totalCost),
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      colors: ["#7cb900"],
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: chartData.map((value) => value.monthYear),
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (y, { dataPointIndex }) {
            if (typeof y !== "undefined") {
              return (
                "<ul class='tooltipul'>" +
                "<li><span>Value (₹):&nbsp;</span>" +
                roundToTwoDecimal(y + "") +
                "</li>" +
                "</ul>"
              );
            }
            return y;
          },
          title: {
            formatter: function (seriesName) {
              return undefined;
            },
          },
        },
        x: {
          formatter: function (x) {
            if (typeof x !== "undefined") {
              return "<span class='title pl-3'>" + x + "</span>";
            }
            return x;
          },
        },
        marker: {
          show: false,
        },
      },
    },
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item lg={5} md={5} sm={12} xs={12}>
          <Box className="card-wrapper custom-tooltip">
            {chartData?.length ? (
              <ReactApexChart
                options={state.options}
                series={state.series}
                type="bar"
                height={350}
              />
            ) : null}
          </Box>
        </Grid>
        <Grid item lg={7} md={7} sm={12} xs={12}>
          <Box variant="div" component="div" className="card-wrapper p-0">
            <TableContainer className="table-listing">
              <Table
                sx={{ minWidth: 650 }}
                aria-label="table"
                className="listing-table"
              >
                <TableHead>
                  <TableRow>
                    {[...allTabColumn].map((item) => (
                      <TableCell align="left">{item.title}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {loader ? (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={3}>
                        {" "}
                        <Loader />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {reportData?.length === 0 ? (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center" colSpan={7}>
                          Record Not Found
                        </TableCell>
                      </TableRow>
                    ) : (
                      <>
                        {reportData?.map((item) => (
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            {[...allTabColumn].map((ele) => (
                              <TableCell align="left">
                                {ele?.formatter
                                  ? ele?.formatter(
                                      getkeyData(item, ele.dataKey)
                                    )
                                  : getkeyData(item, ele.dataKey) || "-"}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell align="left" colSpan={6}></TableCell>

                          <TableCell
                            align="left"
                            colSpan={2}
                            className="amount-hightlight"
                          >
                            <Typography variant="h5" color={"#7cb900"}>
                              {roundToTwoDecimal(totalMaintainance + "")}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <Box variant="div" component="div" className="pagination-wrapper">
              {reportData?.length ? (
                <CustomTablePaginationToolbar
                  count={count}
                  page={currentPage}
                  rowsPerPage={rowperPage}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              ) : null}
            </Box>
          </Box>
          <ExportToPdf
            exportCancel={exportCancel}
            onCloseExport={onCloseExport}
            isExcel={isExcel}
            reportId={2}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AssetTicketDetailsList;
