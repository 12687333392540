import React, { useEffect, useState } from "react";
import { RiDeleteBinLine, RiEyeLine, RiPencilLine } from "react-icons/ri";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Pagination,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import CompanyBranchDeleteModal from "../../../components/common/CompanyBranchDeleteModal";
import { fetchCompanyBranch } from "../../../store/slices/companyBranchSlice";
import { useDispatch } from "react-redux";
import Loader from "../../../components/common/Loader";
import {
  fullSettings,
  updateSettings,
  viewSettings,
} from "../../../utils/permissions";
import WithPermission from "../../../components/common/withPermission";
import { CustomTablePaginationToolbar } from "../../../components/common/Pagination";

const CompanyBranchList = ({ searchBranch }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [companyBranch, setCompanyBranch] = useState([]);
  const [count, setCount] = useState(1);
  const [loader, setLoader] = useState(true);
  const [currentPage, setcurrentPage] = useState(1);
  const [companyBranchDelete, setCompanyBranchDelete] = useState(false);
  const [companyBranchId, setCompanyBranchId] = useState("");
  const [rowperPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    fetchData();
  }, [searchBranch, currentPage, rowperPage]);

  useEffect(() => {
    if (currentPage > 1) {
      setcurrentPage(1);
    }
  }, [searchBranch]);

  const handlePageChange = (event, newPage) => {
    setcurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setcurrentPage(1);
  };

  const fetchData = async () => {
    setLoader(true);
    try {
      const response = await dispatch(
        fetchCompanyBranch(true, currentPage, searchBranch, rowperPage)
      );
      if (response?.success) {
        setCompanyBranch(response?.data?.results);
        setCount(response?.data?.totalCount);
      } else {
        console.error("Failed! Something went wrong.");
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <Box variant="div" component="div" className="tab-wrapper user-listing">
        <TableContainer className="table-listing">
          <Table
            sx={{ minWidth: 650 }}
            aria-label="table"
            className="listing-table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="left">Branch Name</TableCell>
                <TableCell align="left">Branch Description</TableCell>
                <TableCell align="left">Company Name</TableCell>
                <TableCell align="left">Actions</TableCell>
              </TableRow>
            </TableHead>
            {loader ? (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={3}>
                    {" "}
                    <Loader />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {companyBranch?.length === 0 ? (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center" colSpan={3} sx={{ py: 7 }}>
                      Company Branch Not Found..
                    </TableCell>
                  </TableRow>
                ) : (
                  companyBranch.map((item) => (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="left">
                        <Box className="app-list">{item?.name}</Box>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ whiteSpace: "break-spaces !important" }}
                      >
                        {item?.description}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ whiteSpace: "break-spaces !important" }}
                      >
                        {item?.branch_company?.company_name}
                      </TableCell>
                      <TableCell align="left">
                        <Box className="action-btn-wrap">
                          <WithPermission
                            permission={viewSettings}
                            element={
                              <Button
                                variant="outlined"
                                className="action-btn view-btn"
                                startIcon={<RiEyeLine />}
                                disableRipple
                                onClick={() =>
                                  navigate(`/branch/view/${item?.id}`)
                                }
                              ></Button>
                            }
                          />
                          <WithPermission
                            permission={updateSettings}
                            element={
                              <Button
                                variant="outlined"
                                className="action-btn edit-btn"
                                startIcon={<RiPencilLine />}
                                disableRipple
                                onClick={() =>
                                  navigate(`/branch/edit/${item?.id}`)
                                }
                              ></Button>
                            }
                          />
                          <WithPermission
                            permission={fullSettings}
                            element={
                              <Button
                                variant="outlined"
                                className="action-btn dlt-btn"
                                startIcon={<RiDeleteBinLine />}
                                disableRipple
                                onClick={() => {
                                  setCompanyBranchDelete(true);
                                  setCompanyBranchId(item?.id);
                                }}
                              ></Button>
                            }
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {companyBranch?.length > 0 ? (
          <CustomTablePaginationToolbar
            count={count}
            page={currentPage}
            rowsPerPage={rowperPage}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : null}
      </Box>

      <CompanyBranchDeleteModal
        companyBranchDelete={companyBranchDelete}
        setCompanyBranchDelete={setCompanyBranchDelete}
        companyBranchId={companyBranchId}
        setCompanyBranchId={setCompanyBranchId}
        fetchData={fetchData}
      />
    </>
  );
};

export default CompanyBranchList;
