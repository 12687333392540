import { createSlice } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
import axios from "axios";
axios.defaults.withCredentials = true;

const initialState = {
  userList: null,
  loading: false,
  error: null,
};

const userListSlice = createSlice({
  name: "userList",
  initialState,
  reducers: {
    getUserListStart(state) {
      state.loading = true;
      state.error = null;
    },
    getUserListSuccess(state, action) {
      state.loading = false;
      state.userList = action.payload;
    },
    getUserListFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getUserListStart, getUserListSuccess, getUserListFailure } =
  userListSlice.actions;
export default userListSlice.reducer;

export const fetchUserList = (token) => async (dispatch) => {
  try {
    dispatch(getUserListStart());

    const response = await axios.get(
      `${process.env.REACT_APP_LOCAL_CENTRAL_URL}/announcement/authors`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const user = response.data;
    dispatch(getUserListSuccess(user));
  } catch (error) {
    dispatch(getUserListFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error.response.data.message,
        code: error.response.status,
      })
    );
  }
};
