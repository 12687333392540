import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  Pagination,
  Typography,
  Grid,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { fetchReportDetailsList } from "../../../store/slices/reportSlice";
import Loader from "../../../components/common/Loader";
import moment from "moment";
import ExportToPdf from "../../../components/common/exportToPdfModal";
import {
  assetStatusList,
  formatDefaultPrice,
  roundToTwoDecimal,
} from "../../../utils/helper";
import ReactApexChart from "react-apexcharts";
import { CustomTablePaginationToolbar } from "../../../components/common/Pagination";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 0 }}>{children}</Box>}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ReportDetailsList = ({
  applyedFilter,
  exportCancel,
  isExcel,
  onCloseExport,
}) => {
  // START :: TAB WISE LISTING FOR ASSETS TICKET
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [reportData, setReportData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [currentPage, setcurrentPage] = useState(1);
  const [count, setCount] = useState(1);
  const [totalSum, setTotalSum] = useState(0);
  const [chartData, setChartData] = useState([]);
  const [rowperPage, setRowsPerPage] = useState(10);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setcurrentPage(1);
  };

  const tabLabel = (role) => {
    return (
      <Box>
        <span className="user-name">{role}</span>
      </Box>
    );
  };
  // END :: TAB WISE LISTING FOR ASSETS TICKET
  const getTabValueString = (tabValue) => {
    switch (tabValue) {
      case 0:
        return "all";
      case 1:
        return "in_stock_available";
      case 2:
        return "in_service";
      case 3:
        return "under_repair";
      case 4:
        return "out_of_service";
      case 5:
        return "scrap";
      default:
        return "all";
    }
  };
  const handlePageChange = (event, newPage) => {
    setcurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setcurrentPage(1);
  };

  const fetchData = async () => {
    setLoader(true);
    try {
      const response = await dispatch(
        fetchReportDetailsList(
          getTabValueString(value),
          currentPage,
          applyedFilter,
          rowperPage
        )
      );
      if (response?.success) {
        setTotalSum(response?.data?.TotalSum);
        setReportData(
          response?.data?.results.map((item) => ({
            ...item,
            current_status:
              assetStatusList.find((i) => i.value === item.current_status)
                .label ?? "-",
          }))
        );
        setChartData([
          response?.data?.stockserviceInfo,
          response?.data?.serviceInfo,
          response?.data?.UnderrepairInfo,
          response?.data?.outofserviceInfo,
          response?.data?.scarapdataInfo,
        ]);
        setCount(response?.data?.totalCount);
      } else {
        console.error("Failed! Something went wrong.");
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [value, currentPage, applyedFilter, rowperPage]);

  const allTabColumn = [
    { title: "Asset Code", dataKey: "asset_code" },
    { title: "Asset Name", dataKey: "asset_name" },
    {
      title: "Purchase Date",
      dataKey: "purchase_date",
      formatter: (value) => {
        return value ? moment(value).format("DD-MM-YYYY") : "-";
      },
    },
    {
      title: "Purchase Price (₹)",
      dataKey: "purchase_price",
      formatter: (priceToFormat) => {
        return roundToTwoDecimal(priceToFormat + "");
      },
    },
    { title: "Status", dataKey: "current_status" },
  ];
  const column = [
    { title: "Asset Code", dataKey: "asset_code" },
    { title: "Asset Name", dataKey: "asset_name" },
    {
      title: "Purchase Date",
      dataKey: "purchase_date",
      formatter: (value) => {
        return value ? moment(value).format("DD-MM-YYYY") : "-";
      },
    },
    {
      title: "Purchase Price (₹)",
      dataKey: "purchase_price",
    },
  ];

  const getkeyData = (obj, dynamicKey) => {
    const keys = dynamicKey.split(/(\?\.|\.)/); // Split on ?. or .
    const value = keys.reduce((acc, key) => {
      const keyData = keys.shift();
      if (key === "?.") {
        return acc?.[keyData] ?? "-";
      } else if (key === ".") {
        return acc[keyData] ?? "-";
      } else {
        return acc[key] ?? "-";
      }
    }, obj);
    return value;
  };

  const tabValue = [
    "All",
    "In Stock",
    "In Service",
    "Under Repair",
    "Out Of Service",
    "Scrap",
  ];
  const chartLable = [...tabValue].slice(1);

  const state = {
    series: chartData.map((value) => value.value),
    options: {
      chart: {
        width: 380,
        type: "pie",
      },
      labels: chartLable,
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (y, { dataPointIndex }) {
            if (typeof y !== "undefined") {
              return (
                "<ul class='tooltipul'>" +
                "<li><span>Assets #:&nbsp;</span>" +
                chartData[dataPointIndex]?.count
                  ?.toFixed(0)
                  ?.toString()
                  ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                "</li>" +
                "<li><span> Value (₹):&nbsp;</span>" +
                roundToTwoDecimal(y + "") +
                "</li>" +
                "</ul>"
              );
            }
            return y;
          },
          title: {
            formatter: function (seriesName) {
              return "<div class='tooltipTitle'>" + seriesName + "</div>";
            },
          },
        },
      },
      legend: {
        position: "bottom",
      },
      colors: ["#007bff", "#28a745", "#ffc107", "#dc3545", "#6c757d"],
    },
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item lg={5} md={5} sm={12} xs={12}>
          <Box className="card-wrapper">
            {chartData?.length ? (
              <ReactApexChart
                options={state.options}
                series={state.series}
                type="pie"
              />
            ) : null}
          </Box>
        </Grid>
        <Grid item lg={7} md={7} sm={12} xs={12}>
          <Box className="card-wrapper p-0">
            <Box
              variant="div"
              component="div"
              className="tab-wrapper user-listing"
            >
              <Box
                variant="div"
                component="div"
                className="tab-wrapper user-listing"
              >
                <Box
                  variant="div"
                  component="div"
                  className="tab-wrapper user-listing"
                >
                  {/* START :: ASSETS TICKETS TABS NAME */}
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                      className="tab-area"
                    >
                      {tabValue.map((item, i) => (
                        <Tab label={tabLabel(item)} {...a11yProps(i)} />
                      ))}
                    </Tabs>
                  </Box>
                  {/* START :: ASSETS TICKETS TABS NAME */}

                  {tabValue.map((tabName, i) => (
                    <CustomTabPanel
                      value={value}
                      index={i}
                      className="user-listing-tables"
                    >
                      <TableContainer className="table-listing">
                        <Table
                          sx={{ minWidth: 650 }}
                          aria-label="table"
                          className="listing-table"
                        >
                          <TableHead>
                            <TableRow>
                              {[
                                ...(tabName === "All" ? allTabColumn : column),
                              ].map((item) => (
                                <TableCell align="left">{item.title}</TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          {loader ? (
                            <TableBody>
                              <TableRow>
                                <TableCell align="center" colSpan={3}>
                                  {" "}
                                  <Loader />
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          ) : (
                            <TableBody>
                              {reportData?.length === 0 ? (
                                <TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell align="center" colSpan={6}>
                                    Record Not Found
                                  </TableCell>
                                </TableRow>
                              ) : (
                                <>
                                  {reportData?.map((item) => (
                                    <TableRow
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      {[
                                        ...(tabName === "All"
                                          ? allTabColumn
                                          : column),
                                      ].map((ele) => (
                                        <TableCell align="left">
                                          {ele?.formatter
                                            ? ele?.formatter(
                                                getkeyData(item, ele.dataKey)
                                              )
                                            : getkeyData(item, ele.dataKey) ||
                                              "-"}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                  ))}
                                  <TableRow
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell
                                      align="left"
                                      colSpan={3}
                                    ></TableCell>
                                    <TableCell
                                      align="left"
                                      colSpan={2}
                                      className="amount-hightlight"
                                    >
                                      <Typography variant="h3">
                                        {roundToTwoDecimal(totalSum + "")}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                </>
                              )}
                            </TableBody>
                          )}
                        </Table>
                      </TableContainer>
                      <Box
                        variant="div"
                        component="div"
                        className="pagination-wrapper"
                      >
                        {reportData?.length ? (
                          <CustomTablePaginationToolbar
                            count={count}
                            page={currentPage}
                            rowsPerPage={rowperPage}
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        ) : null}
                      </Box>
                    </CustomTabPanel>
                  ))}
                </Box>
              </Box>

              <ExportToPdf
                exportCancel={exportCancel}
                onCloseExport={onCloseExport}
                isExcel={isExcel}
                status={getTabValueString(value)}
                reportId={1}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default ReportDetailsList;
