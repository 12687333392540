import React, { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Drawer,
  Fade,
  Modal,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import {
  RiArrowLeftLine,
  RiFilter2Line,
  RiCloseLine,
  RiArrowDownSLine,
  RiPencilLine,
  RiDeleteBinLine,
} from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";
import * as Yup from "yup";
import ConvertIcon from "../../../assets/images/icons/view-export.svg";
import ExcelExportIcon from "../../../assets/images/icons/ExcelExport-Icon.svg";
import { successToast } from "../../../response/successToast";
import ReportDetailsList from "./ReportDetailsList";
import { fetchUsers } from "../../../store/slices/userSlice";
import { fetchVendor } from "../../../store/slices/vendorSlice";
import { fetchAssetCategory } from "../../../store/slices/assetCategorySlice";
import { fetchAsset } from "../../../store/slices/assetSlice";
import { useFormik } from "formik";
import { DateRangePicker } from "rsuite";
import {
  createTicketsReportFilter,
  deleteTicketsReportFilter,
  editTicketsReportFilter,
  fetchTicketsReportFilterList,
} from "../../../store/slices/reportSlice";
import { getDateRangeOptions } from "../../../utils/helper";
import AssetTicketDetailsList from "./AssetTicketDetailList";

const { afterToday } = DateRangePicker;
// SIDEBAR DRAWER TABBING PANEL
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const atLeastOneString = (fields, message = "At least one field is required") =>
  Yup.mixed().test("atLeastOneString", message, function (value) {
    const { parent } = this;

    const hasAtLeastOneField = fields.some((field) => {
      const fieldValue = parent[field];
      return (
        fieldValue !== undefined &&
        fieldValue !== null &&
        typeof fieldValue === "string" &&
        fieldValue.trim() !== ""
      );
    });

    if (!hasAtLeastOneField) {
      return this.createError({ message, path: this.path });
    }

    return true;
  });

const validationSchema = Yup.object().shape({
  vendor_id: Yup.string().nullable(),
  requested_date: Yup.array()?.length(2).of(Yup.string()).nullable(),
  asset_id: Yup.string().nullable(),
  atLeastOne: atLeastOneString(["vendor_id", "requested_date", "asset_id"]),
});

const ranges = getDateRangeOptions();

const AssetTicketDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [assetsOptions, setAssetsOptions] = useState([]);
  const [selectedSavedFilter, setSelectedSavedFilter] = useState("");
  const users = useSelector((state) => state.users.users);
  const vendor = useSelector((state) => state?.vendor?.vendor);
  const categorys = useSelector((state) => state?.assetCategory?.assetCategory);
  const ticketReportFilter = useSelector(
    (state) => state?.report?.ticketReportFilter
  );
  console.log("Tickets Report Filter", ticketReportFilter);
  const today = new Date();
  const initialSelectedDates = [today, today];
  const [filterApplied, setFilterApplied] = useState(false);
  const [applyedFilter, setApplyedFilter] = useState({});
  const [open, setOpen] = useState(false);
  // SIDEBAR DRAWER TABBING PANEL
  const [value, setValue] = React.useState(0);

  // START :: EXPORT TO PDF MODAL
  const [SaveFilter, setSaveFilterModal] = useState(false);
  // END :: EXPORT TO PDF MODAL

  // START :: EXPORT TO PDF MODAL
  const [DeleteSavedFilter, setDeleteSavedFilterModal] = useState(false);
  // END :: EXPORT TO PDF MODAL
  const [exportCancel, setExportCancel] = useState(false);
  const [isExcel, setIsExcel] = useState(false);
  const onCloseExport = () => {
    setExportCancel(false);
    setIsExcel(false);
  };

  const fetchAssets = async () => {
    setLoader(true);
    try {
      const response = await dispatch(fetchAsset("", 1, "", "", 10000));
      if (response?.success) {
        const formattedOptions = response?.data?.results?.map((asset) => ({
          value: asset.id,
          label: asset?.asset_code + " - " + asset.asset_name,
        }));
        setAssetsOptions(formattedOptions);
      } else {
        console.error("Failed! Something went wrong.");
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      requested_date: null,
      vendor_id: null,
      asset_id: null,
      name: "",
    },
    validationSchema,
    onSubmit: (values) => {
      setLoader(true);
      dispatch(
        isEdit
          ? editTicketsReportFilter(selectedSavedFilter.id, values)
          : createTicketsReportFilter(values)
      )
        .then((response) => {
          if (response?.success) {
            setSaveFilterModal(false);
            successToast(
              response?.message || isEdit
                ? "Filter Updated Successfully"
                : "Filter Added Successfully"
            );
            setValue(1);
            setIsEdit(false);
          } else {
            console.error("failed! Something went wrong");
          }
        })
        .catch((error) => {
          console.error("API call error:", error);
        });
      setLoader(false);
    },
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setIsEdit(false);
    formik.handleReset();
  };

  const onDeleteReportFilter = async () => {
    setLoader(true);
    try {
      const response = await dispatch(
        deleteTicketsReportFilter(selectedSavedFilter.id)
      );

      if (response?.success) {
        successToast(response?.message || "filter Deleted Successfully");
        setDeleteSavedFilterModal(!DeleteSavedFilter);
        dispatch(fetchTicketsReportFilterList());
      } else {
        console.error("Failed! Something went wrong.");
        setDeleteSavedFilterModal(!DeleteSavedFilter);
        setSelectedSavedFilter({});
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    dispatch(fetchTicketsReportFilterList());
  }, [value]);

  useEffect(() => {
    dispatch(
      fetchUsers({
        pagination: false,
      })
    );
    fetchAssets();
    dispatch(fetchVendor(true));
    dispatch(fetchAssetCategory(1, "", true));
  }, []);

  // const assigneeOptions = useMemo(
  //   () =>
  //     users?.results?.map((user) => ({
  //       value: user.id,
  //       label: user.first_name + " " + user.last_name,
  //     })),
  //   [users]
  // );

  const vendorOptions = useMemo(
    () =>
      vendor?.result?.map((vendor) => ({
        value: vendor.id,
        label: vendor.company_name,
      })),
    [vendor]
  );

  // const categoryOptions = useMemo(
  //   () =>
  //     categorys?.results?.map((category) => ({
  //       value: category.id,
  //       label: category.name,
  //     })),
  //   [categorys]
  // );
  const handleDateRangeChange = (value) => {
    if (value !== null) {
      formik.setFieldValue(
        "requested_date",
        value.map((date) => moment(date).format("YYYY-MM-DD"))
      );
    } else {
      formik.setFieldValue(
        "requested_date",
        initialSelectedDates.map((date) => moment(date).format("YYYY-MM-DD"))
      );
    }
  };

  const onCloseDrawer = () => {
    setOpen(false);
    setIsEdit(false);
    formik.handleReset();
  };

  return (
    <>
      {/*  START :: ASSET REPORT DETAILS VIEW */}
      <Box className="content-header view-lead-header report-asset-valuation-wrapper">
        <Box className="filter-apply-wrapper">
          <Typography variant="h2" component="h2" className="title-with-btn">
            <Button
              variant="outlined"
              className="action-btn back-btn"
              startIcon={<RiArrowLeftLine />}
              disableRipple
              onClick={() => navigate("/reports")}
            ></Button>
            Report of Asset Ticket Details
          </Typography>
          {filterApplied && applyedFilter && (
            <>
              <Typography variant="h2" component="h2">
                {" "}
                - &nbsp;
                {applyedFilter ? applyedFilter?.name : ""}
              </Typography>
              <Button
                variant="contained"
                size="large"
                className="outline-btn btn add-btn"
                endIcon={<RiCloseLine />}
                disableRipple
                onClick={() => {
                  setFilterApplied(false);
                  setApplyedFilter({});
                }}
              >
                Remove filter
              </Button>
            </>
          )}
        </Box>
        <Box variant="div" component="div" className="content-header-right">
          <Button
            variant="contained"
            size="large"
            className="primary-btn btn add-btn"
            disableRipple
            onClick={() => setExportCancel(true)}
          >
            Export To PDF{" "}
            <img
              src={ConvertIcon}
              alt="Convert Icon"
              className="add-left-space"
            />
          </Button>
          <Button
            variant="contained"
            size="large"
            className="primary-btn btn add-btn"
            disableRipple
            onClick={() => {
              setExportCancel(true);
              setIsExcel(true);
            }}
          >
            Export To Excel{" "}
            <img
              style={{ paddingLeft: "3px" }}
              src={ExcelExportIcon}
              alt="Convert Icon"
              className="add-left-space"
            />
          </Button>
          <Button
            variant="contained"
            size="large"
            className="primary-btn btn add-btn"
            endIcon={<RiFilter2Line />}
            disableRipple
            onClick={() => setOpen(!open)}
          >
            <Typography
              variant="body1"
              component="span"
              className="filter-counte"
            >
              {ticketReportFilter?.length}
            </Typography>
            Filters
          </Button>
        </Box>
      </Box>
      <Box className="content-wrapper">
        <AssetTicketDetailsList
          applyedFilter={applyedFilter}
          exportCancel={exportCancel}
          onCloseExport={onCloseExport}
          isExcel={isExcel}
        />
      </Box>

      {/* END :: ASSET REPORT DETAILS VIEW */}

      {/*  START :: LEAD FILTER PANEL */}
      <Drawer
        className="side-drawer report-side-drawer"
        anchor="right"
        open={open}
        onClose={onCloseDrawer}
      >
        <Box className="sidebar-tab-wrapper">
          <Box className="sidebar-tab-wrapper">
            <Box className="close-btn" onClick={onCloseDrawer}>
              <RiCloseLine />
            </Box>
            <Box className="sidebar-tab">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  className="tab-label-btn"
                  label="Filters"
                  {...a11yProps(0)}
                />
                <Tab
                  className="tab-label-btn"
                  label="Saved Filters"
                  {...a11yProps(1)}
                />
              </Tabs>
            </Box>
          </Box>
          <CustomTabPanel
            value={value}
            index={0}
            className="filter-content-wrapper"
          >
            <Stack spacing={2} sx={{ padding: "24px" }}>
              {isEdit && (
                <Box variant="div" component="div" className="body">
                  <Typography variant="body1" component="label">
                    Name
                  </Typography>
                  <TextField
                    className="input-field"
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    size="small"
                    placeholder="Enter Save Filter Name"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={
                      formik.touched.name && (
                        <span className="error-message">
                          {formik.errors.name}
                        </span>
                      )
                    }
                  />
                </Box>
              )}
              <Box className="calendar-ui">
                <Typography variant="body1" component="label">
                  Requested Date
                </Typography>
                <DateRangePicker
                  style={{ width: "100%" }}
                  showOneCalendar
                  ranges={ranges}
                  className="modal-date-picker"
                  menuClassName="drawer-date-picker-container"
                  placement="auto"
                  preventOverflow
                  onChange={handleDateRangeChange}
                  name="requested_date"
                  shouldDisableDate={afterToday()}
                  value={formik?.values?.requested_date?.map(
                    (item) => new Date(item)
                  )}
                  onBlur={() => formik.setFieldTouched("requested_date", false)}
                  format="dd-MM-yyyy"
                />
                {formik.touched.requested_date &&
                  formik.errors.requested_date && (
                    <FormHelperText className="error-message">
                      {formik.errors.requested_date}
                    </FormHelperText>
                  )}
              </Box>
              {formik.errors.atLeastOne && (
                <FormHelperText className="error-message">
                  {formik.errors.atLeastOne}
                </FormHelperText>
              )}
              <Box>
                <Typography variant="body1" component="label">
                  Vendor
                </Typography>
                <Select
                  placeholder="Select Vendor"
                  name="vendor_id"
                  className={`muilt-select-field ${
                    formik.touched.vendor_id && formik.errors.vendor_id
                      ? "has-error"
                      : ""
                  }`}
                  options={vendorOptions}
                  classNamePrefix="select"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 10,
                    colors: {
                      ...theme.colors,
                      primary25: "var(--secondary-text-color)",
                      primary: "#8CC714",
                    },
                  })}
                  onBlur={formik.handleBlur}
                  onChange={(selectedOption) => {
                    formik.setFieldValue("vendor_id", selectedOption?.value);
                    formik.setFieldTouched("vendor_id", false);
                  }}
                  value={
                    vendorOptions &&
                    Array.isArray(vendorOptions) &&
                    vendorOptions.find(
                      (option) => option.value === formik.values.vendor_id
                    )
                  }
                  isSearchable
                />
                {formik.touched.vendor_id && formik.errors.vendor_id && (
                  <FormHelperText className="error-message">
                    {formik.errors.vendor_id}
                  </FormHelperText>
                )}
              </Box>
              <Box>
                <Typography variant="body1" component="label">
                  Asset
                </Typography>
                <Select
                  placeholder="Select Asset"
                  name="asset_id"
                  options={assetsOptions}
                  className={`muilt-select-field ${
                    formik.touched.asset_id && formik.errors.asset_id
                      ? "has-error"
                      : ""
                  }`}
                  classNamePrefix="select"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 10,
                    colors: {
                      ...theme.colors,
                      primary25: "var(--secondary-text-color)",
                      primary: "#8CC714",
                    },
                  })}
                  onBlur={formik.handleBlur}
                  onChange={(selectedOption) => {
                    formik.setFieldValue("asset_id", selectedOption?.value);
                    formik.setFieldTouched("asset_id", false);
                  }}
                  value={
                    assetsOptions &&
                    Array.isArray(assetsOptions) &&
                    assetsOptions.find(
                      (option) => option.value === formik.values.asset_id
                    )
                  }
                  isSearchable
                />
                {formik.touched.asset_id && formik.errors.asset_id && (
                  <FormHelperText className="error-message">
                    {formik.errors.asset_id}
                  </FormHelperText>
                )}
              </Box>

              <Box className="filter-button-wrapper drawer-footer-btn">
                <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn green-btn"
                  disableRipple
                  onClick={() => {
                    setApplyedFilter({
                      requested_date: formik.values.requested_date,
                      vendor_id: formik.values.vendor_id,
                      asset_id: formik.values.asset_id,
                    });
                    setOpen(false);
                    setValue(0);
                    setFilterApplied(true);
                  }}
                >
                  Apply Filter
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn"
                  disableRipple
                  onClick={() => {
                    isEdit ? formik.handleSubmit() : setSaveFilterModal(true);
                  }}
                >
                  {isEdit ? "Update" : "Save"}
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  className="secondary-btn btn"
                  disableRipple
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </Button>
              </Box>
            </Stack>
          </CustomTabPanel>
          <CustomTabPanel
            value={value}
            index={1}
            className="filter-content-wrapper"
          >
            <Box className="filer-accordion-wrapper">
              {ticketReportFilter?.map((item) => (
                <Accordion>
                  <AccordionSummary
                    className="accordion-title"
                    expandIcon={<RiArrowDownSLine />}
                    aria-controls="filter-content-1"
                    id="filter-header-1"
                  >
                    <Typography className="title-content">
                      {item.name}
                    </Typography>
                    <Stack
                      className="action-btn-wrap"
                      direction="row"
                      spacing={2}
                    >
                      <Button
                        variant="outlined"
                        className="action-btn view-btn"
                        startIcon={<RiFilter2Line />}
                        disableRipple
                        onClick={(e) => {
                          e.stopPropagation();
                          setApplyedFilter({
                            ...item,
                            //purchase_date: JSON.parse(item.purchase_date),
                          });
                          setOpen(false);
                          setValue(0);
                          setFilterApplied(true);
                        }}
                      />
                      {/* <Button
                        variant="outlined"
                        className="action-btn convert-btn"
                        startIcon={<RiShareLine />}
                        disableRipple
                      ></Button> */}
                      <Button
                        variant="outlined"
                        className="action-btn edit-btn"
                        startIcon={<RiPencilLine />}
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedSavedFilter(item);
                          setIsEdit(true);
                          setValue(0);
                          formik.setValues({
                            name: item?.name,
                            requested_date_date: item?.requested_date,
                            asset_id: item?.asset_id,
                            vendor_id: item?.vendor_id,
                          });
                        }}
                        disableRipple
                      />
                      <Button
                        variant="outlined"
                        className="action-btn dlt-btn"
                        startIcon={<RiDeleteBinLine />}
                        onClick={(e) => {
                          e.stopPropagation();
                          setDeleteSavedFilterModal(true);
                          setSelectedSavedFilter(item);
                        }}
                        disableRipple
                      />
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails className="filter-accordion-details">
                    <Stack spacing={2} className="saved-filter-wrapper">
                      <Box className="saved-filter-content">
                        <Typography
                          variant="body2"
                          component="span"
                          className="title"
                        >
                          Vendor :
                        </Typography>
                        <Typography
                          variant="body2"
                          component="span"
                          className="desc"
                        >
                          {item?.vendor?.company_name ?? "-"}
                        </Typography>
                      </Box>
                      <Box className="saved-filter-content">
                        <Typography
                          variant="body2"
                          component="span"
                          className="title"
                        >
                          Asset :
                        </Typography>
                        <Typography
                          variant="body2"
                          component="span"
                          className="desc"
                        >
                          {item?.asset
                            ? item?.asset?.asset_code +
                              "-" +
                              item?.asset?.asset_name
                            : "-"}
                        </Typography>
                      </Box>
                      <Box className="saved-filter-content">
                        <Typography
                          variant="body2"
                          component="span"
                          className="title"
                        >
                          Requested Dates :
                        </Typography>
                        <Typography
                          variant="body2"
                          component="span"
                          className="desc"
                        >
                          {item?.requested_date?.length
                            ? `${item.requested_date[0]} to ${item.requested_date[0]}`
                            : "-"}
                          {/* {JSON.parse(item?.purchase_date)?.length
                            ? `${JSON.parse(item?.purchase_date)[0]} to ${
                                JSON.parse(item?.purchase_date)[0]
                              }`
                            : "-"} */}
                        </Typography>
                      </Box>
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          </CustomTabPanel>
        </Box>
      </Drawer>
      {/* END :: LEAD FILTER PANEL */}

      {/*  START :: SAVE FILTER MODAL */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={SaveFilter}
        // onClose={ExportToPdfModalClose}
        closeAfterTransition
      >
        <Fade in={SaveFilter}>
          <Box className="modal-wrapper modal-ui">
            <Box className="modal-body">
              <form onSubmit={formik.handleSubmit}>
                <Box
                  variant="div"
                  component="div"
                  className="title"
                  sx={{ marginBottom: "24px" }}
                >
                  <Typography variant="h3" component="h3">
                    Save Filter
                  </Typography>
                </Box>
                <Box variant="div" component="div" className="body">
                  <Typography variant="body1" component="label">
                    Name
                  </Typography>
                  <TextField
                    className="input-field"
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    size="small"
                    placeholder="Enter Save Filter Name"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={
                      formik.touched.name && (
                        <span className="error-message">
                          {formik.errors.name}
                        </span>
                      )
                    }
                  />
                </Box>
                <Box variant="div" component="div" className="footer">
                  <Box variant="div" component="div" className="btn-group">
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      className="primary-btn btn"
                      disableRipple
                      disabled={loader}
                      endIcon={
                        loader && <CircularProgress size={20} color="inherit" />
                      }
                    >
                      Save
                    </Button>
                    <Button
                      variant="contained"
                      size="large"
                      className="secondary-btn btn"
                      disableRipple
                      onClick={() => setSaveFilterModal(false)}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </form>
            </Box>
          </Box>
        </Fade>
      </Modal>
      {/* END :: SAVE FILTER MODAL */}

      {/*  START :: DELETE SAVED FILTER MODAL */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={DeleteSavedFilter}
        closeAfterTransition
      >
        <Fade in={DeleteSavedFilter}>
          <Box className="modal-wrapper modal-ui">
            <Box className="modal-body">
              <Typography variant="h3" component="h3">
                Do you want to delete this saved filter?
              </Typography>
              <Typography variant="body1" component="p">
                This action can’t be undone.
              </Typography>
              <Box variant="div" component="div" className="footer">
                <Box variant="div" component="div" className="btn-group">
                  <Button
                    variant="contained"
                    size="large"
                    className="primary-btn btn"
                    disableRipple
                    onClick={() => onDeleteReportFilter()}
                  >
                    Delete Filter
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    className="secondary-btn btn"
                    disableRipple
                    onClick={() => setDeleteSavedFilterModal(false)}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
      {/* END :: DELETE SAVED FILTER MODAL */}
    </>
  );
};

export default AssetTicketDetails;
