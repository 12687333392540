import { createSlice } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
import api from "../../components/common/commonfunctionforapi";

const initialState = {
  asset: [],
  assetFilter: [],
  isLoading: false,
  success: false,
  error: null,
};

const AssetSlice = createSlice({
  name: "asset",
  initialState,
  reducers: {
    createAssetStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    createAssetSuccess: (state, action) => {
      state.isLoading = false;
      state.success = true;
      // state.Asset.push(action.payload);
    },
    createAssetFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
    editAssetStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    editAssetSuccess: (state, action) => {
      state.isLoading = false;
      state.success = true;
      const categoryIndex = state.Asset.findIndex(
        (category) => category.id === action.payload.id
      );
      if (categoryIndex !== -1) {
        state.Asset[categoryIndex] = action.payload;
      }
    },
    editAssetFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
    deleteAssetStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    deleteAssetSuccess: (state, action) => {
      state.isLoading = false;
      state.success = true;
      // state.Asset = state.Asset.filter(
      //   (category) => category.id !== action.payload
      // );
    },
    deleteAssetFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
    fetchAssetStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    fetchAssetSuccess: (state, action) => {
      state.isLoading = false;
      state.success = true;
      state.Asset = action.payload;
    },
    fetchAssetFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
    fetchAssetByIdStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    fetchAssetByIdSuccess: (state, action) => {
      state.isLoading = false;
      state.success = true;
      // state.Asset.push(action.payload);
    },
    fetchAssetByIdFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
    fetchAssetByAssetStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    fetchAssetByAssetSuccess: (state, action) => {
      state.isLoading = false;
      state.success = true;
      // state.Asset.push(action.payload);
    },
    fetchAssetByAssetFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
    fetchAssetActivityStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    fetchAssetActivitySuccess: (state, action) => {
      state.isLoading = false;
      state.success = true;
      // state.Asset = action.payload;
    },
    fetchAssetActivityFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
    fetchAssetFilterListStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    fetchAssetFilterListSuccess: (state, action) => {
      state.isLoading = false;
      state.success = true;
      state.assetFilter = action.payload;
    },
    fetchAssetFilterListFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
    createAssetFilterStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    createAssetFilterSuccess: (state, action) => {
      state.isLoading = false;
      state.success = true;
    },
    createAssetFilterFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
    editAssetFilterStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    editAssetFilterSuccess: (state, action) => {
      state.isLoading = false;
      state.success = true;
    },
    editAssetFilterFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
    deleteAssetFilterStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    deleteAssetFilterSuccess: (state, action) => {
      state.isLoading = false;
      state.success = true;
    },
    deleteAssetFilterFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const {
  createAssetStart,
  createAssetSuccess,
  createAssetFailure,
  editAssetStart,
  editAssetSuccess,
  editAssetFailure,
  deleteAssetStart,
  deleteAssetSuccess,
  deleteAssetFailure,
  fetchAssetStart,
  fetchAssetSuccess,
  fetchAssetFailure,
  fetchAssetByIdStart,
  fetchAssetByIdSuccess,
  fetchAssetByIdFailure,
  fetchAssetByAssetStart,
  fetchAssetByAssetSuccess,
  fetchAssetByAssetFailure,
  fetchAssetActivityStart,
  fetchAssetActivitySuccess,
  fetchAssetActivityFailure,
  fetchAssetFilterListStart,
  fetchAssetFilterListSuccess,
  fetchAssetFilterListFailure,
  createAssetFilterStart,
  createAssetFilterSuccess,
  createAssetFilterFailure,
  editAssetFilterStart,
  editAssetFilterSuccess,
  editAssetFilterFailure,
  deleteAssetFilterStart,
  deleteAssetFilterSuccess,
  deleteAssetFilterFailure,
} = AssetSlice.actions;

export default AssetSlice.reducer;

export const createAsset = (assetData) => async (dispatch) => {
  try {
    dispatch(createAssetStart());

    const response = await api.post(`/asset`, assetData);

    const asset = response;
    dispatch(createAssetSuccess(asset?.data));
    return { success: true, message: response?.message };
  } catch (error) {
    dispatch(createAssetFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error?.response?.data?.message,
        code: error?.response?.status,
      })
    );
    return { success: false, error: error?.response?.data?.message };
  }
};

export const editAsset = (assetData, id) => async (dispatch) => {
  try {
    dispatch(editAssetStart());

    const response = await api.patch(`/asset/${id}`, assetData);

    const asset = response?.data;
    // asset.id = id;
    // dispatch(editAssetSuccess(asset));
    return { success: true, message: asset?.message };
  } catch (error) {
    dispatch(editAssetFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error?.response?.data?.message,
        code: error?.response?.status,
      })
    );
    return { success: false, error: error?.response?.data?.message };
  }
};

export const deleteAsset = (assetId) => async (dispatch) => {
  try {
    dispatch(deleteAssetStart());

    const response = await api.delete(`/asset/${assetId}`);

    dispatch(deleteAssetSuccess(assetId));
    return { success: true, message: response?.message };
  } catch (error) {
    dispatch(deleteAssetFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error?.response?.data?.message,
        code: error?.response?.status,
      })
    );
    return { success: false, error: error?.response?.data?.message };
  }
};

export const fetchAsset =
  (value, page, search, applyedFilter, rows, asset_owner) =>
  async (dispatch) => {
    try {
      dispatch(fetchAssetStart());

      let url = `/asset?status=${value}&page=${page}&rows=${rows}${
        applyedFilter?.category_id
          ? `&category_id=${applyedFilter?.category_id}`
          : ""
      }${
        applyedFilter?.assignee_id
          ? `&assignee_id=${applyedFilter.assignee_id}`
          : ""
      }${
        applyedFilter?.company_id
          ? `&company_id=${applyedFilter.company_id}`
          : ""
      }
      ${
        applyedFilter?.branch_id ? `&branch_id=${applyedFilter.branch_id}` : ""
      }`;
      if (search) {
        url += `&search=${search}`;
      }
      if (asset_owner && asset_owner !== "0") {
        asset_owner = asset_owner === "1" ? "Client Asset" : "Company Asset";
        url += `&asset_owner=${asset_owner}`;
      }

      const response = await api.get(url);
      const asset = response;
      dispatch(fetchAssetSuccess(asset?.data));
      return { success: true, data: asset?.data };
    } catch (error) {
      dispatch(fetchAssetFailure(error.message));
      dispatch(
        setFeedback({
          status: "fail",
          message: error?.response?.data?.message,
          code: error?.response?.status,
        })
      );
    }
  };

export const fetchAssetById = (assetId) => async (dispatch) => {
  try {
    dispatch(fetchAssetByIdStart());

    const response = await api.get(`/asset/${assetId}`);

    const asset = response?.data;
    dispatch(fetchAssetByIdSuccess(asset));
    return { success: true, message: response?.message, data: asset };
  } catch (error) {
    dispatch(fetchAssetByIdFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error?.response?.data?.message,
        code: error?.response?.status,
      })
    );
  }
};

//fetch asset tickets assigned for specified asset
export const fetchAssetTicketsByAsset = (assetId) => async (dispatch) => {
  try {
    dispatch(fetchAssetByAssetStart());
    const response = await api.get(`/asset/asset-ticket/${assetId}`);
    const asset = response?.data;
    dispatch(fetchAssetByAssetSuccess(asset));
    return { success: true, message: response?.message, data: asset };
  } catch (error) {
    dispatch(fetchAssetByAssetFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error?.response?.data?.message,
        code: error?.response?.status,
      })
    );
  }
};

export const fetchAssetActivity = (assetId) => async (dispatch) => {
  try {
    dispatch(fetchAssetActivityStart());
    const response = await api.get(`/activity-log/${assetId}`);
    const asset = response?.data;
    dispatch(fetchAssetActivitySuccess(asset));
    return { success: true, message: response?.message, data: asset };
  } catch (error) {
    dispatch(fetchAssetActivityFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error?.response?.data?.message,
        code: error?.response?.status,
      })
    );
  }
};

export const fetchAssetFilterList =
  ({ allFilters, filter_id }) =>
  async (dispatch) => {
    try {
      dispatch(fetchAssetFilterListStart());

      let url = `/asset/filter?allFilters=${allFilters}&filter_id=${filter_id}`;
      const response = await api.get(url);
      const report = response;
      dispatch(fetchAssetFilterListSuccess(report?.data));
      return { success: true, data: report };
    } catch (error) {
      dispatch(fetchAssetFilterListFailure(error.message));
      dispatch(
        setFeedback({
          status: "fail",
          message: error?.response?.data?.message,
          code: error?.response?.status,
        })
      );
    }
  };

export const editAssetFilter = (id, filterData) => async (dispatch) => {
  try {
    dispatch(editAssetFilterStart());

    const response = await api.patch(`/asset/filter/${id}`, filterData);

    dispatch(editAssetFilterSuccess(response?.data));
    return { success: true, message: response?.message };
  } catch (error) {
    dispatch(editAssetFilterFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error?.response?.data?.message,
        code: error?.response?.status,
      })
    );
    return { success: false, error: error?.response?.data?.message };
  }
};

export const createAssetFilter = (filterData) => async (dispatch) => {
  try {
    dispatch(createAssetFilterStart());

    const response = await api.post(
      `/asset/filter`,
      Object.fromEntries(
        Object.entries(filterData).filter(([key, value]) => value !== null)
      )
    );

    dispatch(createAssetFilterSuccess(response?.data));
    return { success: true, message: response?.message };
  } catch (error) {
    dispatch(createAssetFilterFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error?.response?.data?.message,
        code: error?.response?.status,
      })
    );
    return { success: false, error: error?.response?.data?.message };
  }
};

export const deleteAssetFilter = (FilterId) => async (dispatch) => {
  try {
    dispatch(deleteAssetFilterStart());
    const response = await api.delete(`/asset/filter/${FilterId}`);
    dispatch(deleteAssetFilterSuccess(FilterId));
    return { success: true, message: response?.message };
  } catch (error) {
    dispatch(deleteAssetFilterFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error?.response?.data?.message,
        code: error?.response?.status,
      })
    );
    return { success: false, error: error?.response?.data?.message };
  }
};
