import React, { useState, useEffect, useCallback } from "react";

import { Box, TextField, Typography } from "@mui/material";
import { RiCloseCircleLine, RiSearchLine } from "react-icons/ri";

import UserList from "./userList";

import { useDispatch } from "react-redux";
import { fetchUsers } from "../../../store/slices/userSlice";
import { useLocation } from "react-router-dom";

const Users = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  let { state } = location;
  if (state === null) {
    state = {
      value: 0,
      currentPage: 1,
      rowperPage: 10,
      searchUser: "",
    };
  }

  const [currentPage, setCurrentPage] = useState(state.currentPage);
  const [rowperPage, setRowsPerPage] = useState(state.rowperPage);
  const [value, setValue] = useState(state.value);
  const [searchUser, setSearchUser] = useState(state.searchUser);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const getTabValueString = (tabValue) => {
    switch (tabValue) {
      case 0:
        return "active";
      case 1:
        return "archive";
      default:
        return "active";
    }
  };

  useEffect(() => {
    dispatch(
      fetchUsers({
        status: getTabValueString(value),
        page: currentPage,
        rows: rowperPage,
        search: searchUser,
        pagination: true,
      })
    )
      .unwrap()
      .then((data) => {})
      .catch((error) => {});
  }, [dispatch, value, currentPage, searchUser, rowperPage]);

  useCallback(() => {
    if (currentPage > 1) {
      setCurrentPage(1);
    }
  }, [searchUser]);

  const clearSearchHandler = () => {
    setSearchUser("");
    setCurrentPage(1);
  };

  return (
    <>
      {/*  START :: USERS LISTING VIEW */}
      <Box className="content-header project-list-header user-module-wrapper">
        <Typography variant="h2" component="h2">
          Users
        </Typography>
        <Box variant="div" component="div" className="content-header-right">
          <Box variant="div" component="div" className="search-bar">
            <RiSearchLine />
            <TextField
              fullWidth
              id="outlined-basic-search"
              label=""
              variant="outlined"
              size="small"
              placeholder="Search"
              onChange={(e) => setSearchUser(e.target.value)}
              value={searchUser}
            />
            {searchUser && (
              <RiCloseCircleLine
                className="cross-line"
                onClick={clearSearchHandler}
              />
            )}
          </Box>
        </Box>
      </Box>

      <Box className="content-layout">
        <UserList
          onPageChange={handlePageChange}
          searchUser={searchUser}
          value={value}
          onValueChange={setValue}
          currentPage={currentPage}
          rowperPage={rowperPage}
          onChangerow={setRowsPerPage}
        />
      </Box>
      {/* END :: USERS LISTING VIEW */}
    </>
  );
};

export default Users;
