import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import {
  Box,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
  TextareaAutosize,
  CircularProgress,
  FormHelperText,
  InputAdornment,
} from "@mui/material";
import { RiArrowLeftLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { createAsset } from "../../../store/slices/assetSlice";
import { successToast } from "../../../response/successToast";
import { fetchAssetCategory } from "../../../store/slices/assetCategorySlice";
import {
  fetchCompanyBranch,
  fetchCompanyProjects,
} from "../../../store/slices/companyBranchSlice";
import { fetchVendor } from "../../../store/slices/vendorSlice";
import { fetchUsers } from "../../../store/slices/userSlice";
import moment from "moment";
import { roundToTwoDecimal } from "../../../utils/helper";
import { fetchAsset } from "../../../store/slices/assetSlice";
import api from "../../../components/common/commonfunctionforapi";

// STATUS OPTIONS
const statusList = [
  { value: "in_service", label: "In Service" },
  { value: "under_repair", label: "Under Repair" },
  { value: "out_of_service", label: "Out Of Service" },
  { value: "in_stock_available", label: "In Stock" },
  { value: "scrap", label: "Scrap" },
  // { value: "returned", label: "Returned" },
];

// Month OPTIONS
const monthList = [
  { value: "0", label: "0" },
  { value: "1", label: "1 Month" },
  { value: "2", label: "2 Months" },
  { value: "3", label: "3 Months" },
  { value: "4", label: "4 Months" },
  { value: "5", label: "5 Months" },
  { value: "6", label: "6 Months" },
  { value: "7", label: "7 Months" },
  { value: "8", label: "8 Months" },
  { value: "9", label: "9 Months" },
  { value: "10", label: "10 Months" },
  { value: "11", label: "11 Months" },
];

// Year OPTIONS
const YearList = [
  { value: "0", label: "0" },
  { value: "1", label: "1 Year" },
  { value: "2", label: "2 Years" },
  { value: "3", label: "3 Years" },
  { value: "4", label: "4 Years" },
  { value: "5", label: "5 Years" },
  { value: "6", label: "6 Years" },
  { value: "7", label: "7 Years" },
  { value: "8", label: "8 Years" },
  { value: "9", label: "9 Years" },
  { value: "10", label: "10 Years" },
];

var validationSchema;
const AssetCreate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [vendorList, setVendorList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [clientName, setclientName] = useState("");
  const [projectName, setprojectName] = useState("");
  const [projectList, setProjectList] = useState([]);
  const [year, setYear] = useState(0);
  const [month, setMonth] = useState(0);
  const [userList, setUserList] = useState([]);
  const purchaseInputRef = useRef(null);
  const CompanyInputRef = useRef(null);
  const ITValueInputRef = useRef(null);
  const companyList = [
    { value: 1, label: "Crest Infosystems Pvt. Ltd." },
    { value: 2, label: "Crest Skillserve Pvt. Ltd." },
  ];
  const ownerList = [
    { value: 1, label: "Client " },
    { value: 2, label: "Company " },
  ];

  const formik = useFormik({
    initialValues: {
      // client_name: "",
      project_name: "",
      asset_owner: 2,
      asset_code: "",
      asset_category_id: null,
      asset_company_id: null,
      asset_branch_id: null,
      asset_name: "",
      asset_description: "",
      Warranty_description: "",
      current_status: "",
      assign_to: null,
      assign_date: null,
      vendor_id: "",
      purchase_date: "",
      purchase_price: "",
      current_value_per_company_act: "",
      current_value_per_it_act: "",
      warranty_period: "",
    },
    validationSchema,
    onSubmit: (values) => {
      setLoader(true);
      values.warranty_period = year + "-" + month;
      values.purchase_price = values.purchase_price.replace(/,/g, "");
      values.current_value_per_company_act =
        values.current_value_per_company_act.replace(/,/g, "");
      values.current_value_per_it_act = values.current_value_per_it_act.replace(
        /,/g,
        ""
      );
      const { asset_code, ...payload } = values;
      // payload={...payload,asset_owner:formik?.values?.asset_owner===1?'Clent Asset':'Company Asset'}

      dispatch(
        createAsset({
          ...payload,
          client_name: clientName,
          asset_owner:
            formik?.values?.asset_owner === 1
              ? "Client Asset"
              : "Company Asset",

          vendor_id:
            formik?.values?.asset_owner === 1 ? 1 : formik?.values?.vendor_id,
          asset_category_id:
            formik?.values?.asset_owner === 1
              ? 1
              : formik?.values?.asset_category_id,
          purchase_price:
            formik?.values?.asset_owner === 1
              ? "0.00"
              : formik?.values?.purchase_price,
          purchase_date:
            formik?.values?.asset_owner === 1
              ? new Date()
              : formik?.values?.purchase_date,
        })
      )
        .then((response) => {
          if (response?.success) {
            successToast(response?.message || "Asset Added Successfully");
            navigate("/assets");
          } else {
            console.error("failed! Something went wrong");
          }
        })
        .catch((error) => {
          console.error("API call error:", error);
        });
      setLoader(false);
    },
  });
  validationSchema = Yup.object().shape({
    asset_owner: Yup.string().required("Please select asset owner"),
    // client_name:
    //   formik.values.asset_owner === 1
    //     ? Yup.string().required("Please enter client name")
    //     : null,
    project_name:
      formik.values.asset_owner === 1
        ? Yup.string().required("Please select project")
        : null,
    current_status: Yup.string().required("Please select current status"),
    vendor_id:
      formik.values.asset_owner === 2
        ? Yup.string().required("Please select asset vendor")
        : null,
    asset_name: Yup.string().required("Please enter asset name"),
    purchase_date:
      formik.values.asset_owner === 2
        ? Yup.date().required("Please select purchase date")
        : null,
    assign_date: Yup.date().required("Please select assign date"),
    asset_category_id:
      formik.values.asset_owner === 2
        ? Yup.string().required("Please select asset category")
        : null,
    purchase_price:
      formik.values.asset_owner === 2
        ? Yup.string().required("Please enter purchase price")
        : null,
    asset_branch_id: Yup.string().required("Please select asset branch"),
    asset_company_id: Yup.string().required("Please select asset Company"),
    current_value_per_company_act:
      formik.values.asset_owner === 2
        ? Yup.string().required("Please enter Current value(Per Company Act)")
        : null,
    asset_description: Yup.string().required("Please enter asset description"),
    Warranty_description:
      formik.values.asset_owner === 2
        ? Yup.string().required("Please enter warranty description")
        : null,
    current_value_per_it_act:
      formik.values.asset_owner === 2
        ? Yup.string().required("Please enter current value(Per Company Act)")
        : null,
    warranty_period:
      formik.values.asset_owner === 2
        ? Yup.string().required("Please select warranty period")
        : null,
    assign_to: Yup.string().required("Please select assignee"),
  });

  const fetchAssets = async () => {
    setLoader(true);
    try {
      const response = await dispatch(
        fetchAsset("in_service", 1, "", "", 10000)
      );
      if (response?.success) {
        formik.setFieldValue("asset_code", response?.data?.asset_code);
      } else {
        console.error("Failed! Something went wrong.");
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };

  const fetchAssetCategoryList = async () => {
    setLoader(true);
    try {
      const listing = true;
      const response = await dispatch(fetchAssetCategory(1, "", listing));

      if (response?.success) {
        const formattedOptions = response?.data?.results?.map((category) => ({
          value: category.id,
          label: category.name,
        }));
        setCategoryList(formattedOptions);
      } else {
        console.error("Failed! Something went wrong.");
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };
  const fetchVendorList = async () => {
    setLoader(true);
    try {
      const response = await dispatch(fetchVendor(true));

      if (response?.success) {
        const formattedOptions = response?.data?.result?.map((vendor) => ({
          value: vendor.id,
          label: vendor.company_name,
        }));
        setVendorList(formattedOptions);
      } else {
        console.error("Failed! Something went wrong.");
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };
  const fetchBranchList = async (companyId) => {
    setLoader(true);
    try {
      const response = await dispatch(
        fetchCompanyBranch(false, undefined, undefined, undefined, companyId)
      );
      if (response?.success) {
        const formattedOptions = response?.data?.results?.map((branch) => ({
          value: branch.id,
          label: branch.name,
        }));
        setBranchList(formattedOptions);
      } else {
        console.error("Failed! Something went wrong.");
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };
  const fetchProjects = async (companyId) => {
    setLoader(true);
    try {
      const response = await dispatch(fetchCompanyProjects());
      if (response?.success) {
        const formattedOptions = response?.data?.map((p) => ({
          value: p.project_name,
          label: p.project_name,
          clientDetais: p.client_contacts,
        }));
        setProjectList(formattedOptions);
      } else {
        console.error("Failed! Something went wrong.");
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };
  const fetchUsersList = async () => {
    setLoader(true);
    try {
      const response = await dispatch(
        fetchUsers({ pagination: false, allusers: "yes" })
      );
      if (response?.payload) {
        const formattedOptions = response?.payload?.results?.map((user) => ({
          value: user.id,
          label:
            user?.employee_number +
            " - " +
            user.first_name +
            " " +
            user.last_name,
        }));
        setUserList(formattedOptions);
      } else {
        console.error("Failed! Something went wrong.");
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    fetchAssetCategoryList();
    fetchVendorList();
    fetchBranchList();
    fetchUsersList();
    fetchAssets();
    fetchProjects();
  }, []);

  const getFieldProps = (field) => ({
    id: `outlined-basic-${field.name}`,
    fullWidth: true,
    variant: "outlined",
    size: "small",
    placeholder: field.placeholder || `Enter ${field.label}`,
    name: field.name,
    value: formik.values[field.name],
    onChange: formik.handleChange,
    onBlur: formik.handleBlur,
    error: formik.touched[field.name] && Boolean(formik.errors[field.name]),
    helperText: formik.touched[field.name] && formik.errors[field.name] && (
      <FormHelperText className="error-message">
        {formik.errors[field.name]}
      </FormHelperText>
    ),
  });

  //Prevent the default scroll behavior for number inputs
  const handleWheelChange = (e) => {
    e.target.blur();
    e.stopPropagation();
  };

  const handleKeyDown = (e) => {
    const allowedKeys = [
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
      "Enter",
      "Home",
      "End",
    ];
    const isAllowedKey = allowedKeys.includes(e.key);
    const isNumeric = /^[0-9.]+$/.test(e.key);

    if (!isAllowedKey && !isNumeric) {
      e.preventDefault();
    }
  };

  const handleCompanyChange = (selectedOption) => {
    if (formik.values.asset_owner === 1) {
      fetchBranchList(1);
    } else {
      formik.setFieldValue("asset_company_id", selectedOption?.value);
      formik.setFieldValue("asset_branch_id", null);
      fetchBranchList(selectedOption?.value);
    }
    formik.setFieldTouched("asset_company_id", false);
  };
  useEffect(() => {
    const client = projectList
      .filter((p) => p.value === formik.values.project_name)
      .map((p) => p.clientDetais.contact_person_name);
    setclientName(client[0]);
  }, [projectName]);

  return (
    <>
      <Box className="content-header">
        <Typography variant="h2" component="h2" className="title-with-btn">
          <Button
            variant="outlined"
            className="action-btn back-btn"
            startIcon={<RiArrowLeftLine />}
            disableRipple
            onClick={() => navigate("/assets")}
          ></Button>
          Add Asset
        </Typography>
      </Box>

      <Box className="content-wrapper">
        <Box className="card-wrapper">
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2} sx={{ marginBottom: "15px" }}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  Asset Owner
                  <Typography
                    variant="body1"
                    component="label"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </Typography>
                <Select
                  placeholder="Select Asset Owner"
                  name="asset_owner"
                  options={ownerList}
                  className={`muilt-select-field ${
                    formik.touched.asset_owner && formik.errors.asset_owner
                      ? "has-error"
                      : ""
                  }`}
                  classNamePrefix="select"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 10,
                    colors: {
                      ...theme.colors,
                      primary25: "var(--secondary-text-color)",
                      primary: "var(--green)",
                    },
                  })}
                  onBlur={formik.handleBlur}
                  onChange={(selectedOption) => {
                    formik.setFieldValue("asset_owner", selectedOption?.value);
                    formik.setFieldTouched("asset_owner", false);
                    if (selectedOption?.value === 1) {
                      formik.setFieldValue("asset_company_id", 1);
                      formik.setFieldValue("asset_branch_id", null);
                      fetchBranchList(1);
                    }
                  }}
                  value={
                    ownerList &&
                    Array.isArray(ownerList) &&
                    ownerList.find(
                      (option) => option.value === formik.values.asset_owner
                    )
                  }
                  isSearchable // Optional: Enable searching in the dropdown
                />
                {formik.touched.asset_owner && formik.errors.asset_owner && (
                  <FormHelperText className="error-message">
                    {formik.errors.asset_owner}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}></Grid>
            </Grid>
            <Grid container spacing={2}>
              {/* project name */}
              {formik.values.asset_owner === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Project Name
                    <Typography
                      variant="body1"
                      component="label"
                      className="asterisk"
                    >
                      *
                    </Typography>
                  </Typography>
                  <Select
                    placeholder="Select Project"
                    name="vendor_id"
                    options={projectList}
                    className={`muilt-select-field ${
                      formik.touched.project_name && formik.errors.project_name
                        ? "has-error"
                        : ""
                    }`}
                    classNamePrefix="select"
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 10,
                      colors: {
                        ...theme.colors,
                        primary25: "var(--secondary-text-color)",
                        primary: "var(--green)",
                      },
                    })}
                    onBlur={formik.handleBlur}
                    onChange={(selectedOption) => {
                      formik.setFieldValue(
                        "project_name",
                        selectedOption?.value
                      );
                      formik.setFieldTouched("project_name", false);
                      setprojectName(selectedOption?.value);
                    }}
                    value={
                      projectList &&
                      Array.isArray(projectList) &&
                      projectList.find(
                        (option) => option.value === formik.values.project_name
                      )
                    }
                    isSearchable // Optional: Enable searching in the dropdown
                  />
                  {formik.touched.project_name &&
                    formik.errors.project_name && (
                      <FormHelperText className="error-message">
                        {formik.errors.project_name}
                      </FormHelperText>
                    )}
                </Grid>
              )}
              {/* client name */}
              {formik.values.asset_owner === 1 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Client Name
                    <Typography
                      variant="body1"
                      component="label"
                      className="asterisk"
                    >
                      *
                    </Typography>
                  </Typography>
                  <TextField
                    className="input-field"
                    placeholder="Client Name"
                    // {...getFieldProps({
                    //   name: "client_name",
                    //   required: true,
                    //   placeholder: "Client Name",
                    // })}
                    // onBlur={() => formik.setFieldTouched("client_name", false)}
                    value={clientName}
                    disabled
                  />
                </Grid>
              )}

              {/* Asset Code */}
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  Asset Code
                  <Typography
                    variant="body1"
                    component="label"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </Typography>

                <TextField
                  className="input-field"
                  {...getFieldProps({ name: "asset_code" })}
                  disabled
                />
              </Grid>
              {/* Vendor */}
              {formik.values.asset_owner === 2 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Vendor
                    <Typography
                      variant="body1"
                      component="label"
                      className="asterisk"
                    >
                      *
                    </Typography>
                  </Typography>
                  <Select
                    placeholder="Select Vendor"
                    name="vendor_id"
                    options={vendorList}
                    className={`muilt-select-field ${
                      formik.touched.vendor_id && formik.errors.vendor_id
                        ? "has-error"
                        : ""
                    }`}
                    classNamePrefix="select"
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 10,
                      colors: {
                        ...theme.colors,
                        primary25: "var(--secondary-text-color)",
                        primary: "var(--green)",
                      },
                    })}
                    onBlur={formik.handleBlur}
                    onChange={(selectedOption) => {
                      formik.setFieldValue("vendor_id", selectedOption?.value);
                      formik.setFieldTouched("vendor_id", false);
                    }}
                    value={
                      vendorList &&
                      Array.isArray(vendorList) &&
                      vendorList.find(
                        (option) => option.value === formik.values.vendor_id
                      )
                    }
                    isSearchable // Optional: Enable searching in the dropdown
                  />
                  {formik.touched.vendor_id && formik.errors.vendor_id && (
                    <FormHelperText className="error-message">
                      {formik.errors.vendor_id}
                    </FormHelperText>
                  )}
                </Grid>
              )}
              {/* Asset Name */}
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  Asset Name
                  <Typography
                    variant="body1"
                    component="label"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </Typography>
                <TextField
                  className="input-field"
                  {...getFieldProps({
                    name: "asset_name",
                    required: true,
                    placeholder: "Asset Name",
                  })}
                  onBlur={() => formik.setFieldTouched("asset_name", false)}
                />
              </Grid>
              {/* Purchase Date */}
              {formik.values.asset_owner === 2 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Purchase Date
                    <Typography
                      variant="body1"
                      component="label"
                      className="asterisk"
                    >
                      *
                    </Typography>
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          fullWidth
                          id="outlined-basic-date"
                          label="Purchase Date"
                          variant="outlined"
                          size="small"
                          placeholder="Select purchase date"
                          name="purchase_date"
                          value={formik.values.purchase_date}
                          onBlur={() =>
                            formik.setFieldTouched("purchase_date", false)
                          }
                        />
                      )}
                      className={`input-field ${
                        formik.touched.purchase_date &&
                        formik.errors.purchase_date
                          ? "error"
                          : ""
                      }`}
                      onChange={(date) => {
                        formik.setFieldValue("purchase_date", date);
                      }}
                      maxDate={new Date(moment())}
                      format="dd-MM-yyyy"
                    />
                    {formik.touched.purchase_date &&
                      formik.errors.purchase_date && (
                        <FormHelperText className="error-message">
                          {formik.errors.purchase_date}
                        </FormHelperText>
                      )}
                  </LocalizationProvider>
                </Grid>
              )}
              {/* Asset Category */}
              {formik.values.asset_owner === 2 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Asset Category
                    <Typography
                      variant="body1"
                      component="label"
                      className="asterisk"
                    >
                      *
                    </Typography>
                  </Typography>
                  <Select
                    placeholder="Select Asset Category"
                    name="asset_category_id"
                    options={categoryList}
                    className={`muilt-select-field ${
                      formik.touched.asset_category_id &&
                      formik.errors.asset_category_id
                        ? "has-error"
                        : ""
                    }`}
                    classNamePrefix="select"
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 10,
                      colors: {
                        ...theme.colors,
                        primary25: "var(--secondary-text-color)",
                        primary: "var(--green)",
                      },
                    })}
                    onBlur={formik.handleBlur}
                    onChange={(selectedOption) => {
                      formik.setFieldValue(
                        "asset_category_id",
                        selectedOption?.value
                      );
                      formik.setFieldTouched("asset_category_id", false);
                    }}
                    value={categoryList.find(
                      (option) =>
                        option.value === formik.values.asset_category_id
                    )}
                    isSearchable // Optional: Enable searching in the dropdown
                  />
                  {formik.touched.asset_category_id &&
                    formik.errors.asset_category_id && (
                      <FormHelperText className="error-message">
                        {formik.errors.asset_category_id}
                      </FormHelperText>
                    )}
                </Grid>
              )}
              {/* Purchase Price */}
              {formik.values.asset_owner === 2 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Purchase Price
                    <Typography
                      variant="body1"
                      component="label"
                      className="asterisk"
                    >
                      *
                    </Typography>
                  </Typography>
                  <Box className="field-icon">
                    <div style={{ position: "relative" }}>
                      <TextField
                        inputRef={purchaseInputRef}
                        onWheel={handleWheelChange}
                        onKeyDown={handleKeyDown}
                        // type="number"
                        className="input-field"
                        {...getFieldProps({
                          name: "purchase_price",
                          required: true,
                          placeholder: "Purchase Price",
                        })}
                        onBlur={(e) => {
                          const { value } = e.target;
                          const roundedValue = roundToTwoDecimal(value);
                          formik.setFieldValue("purchase_price", roundedValue);
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Typography
                                variant="body1"
                                component="label"
                                className="rupee-symbol"
                              >
                                ₹
                              </Typography>
                            </InputAdornment>
                          ),
                          style: { paddingLeft: "40px" },
                        }}
                      />
                    </div>
                  </Box>
                </Grid>
              )}
              {/* Asset Company */}
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  Asset Company
                  <Typography
                    variant="body1"
                    component="label"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </Typography>
                <Select
                  placeholder="Select Asset Company"
                  name="asset_company_id"
                  options={companyList}
                  className={`muilt-select-field ${
                    formik.touched.asset_company_id &&
                    formik.errors.asset_company_id
                      ? "has-error"
                      : ""
                  }`}
                  classNamePrefix="select"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 10,
                    colors: {
                      ...theme.colors,
                      primary25: "var(--secondary-text-color)",
                      primary: "var(--green)",
                    },
                  })}
                  onBlur={formik.handleBlur}
                  onChange={(selectedOption) => {
                    handleCompanyChange(selectedOption);
                  }}
                  value={companyList.find(
                    (option) => option.value === formik.values.asset_company_id
                  )}
                  isSearchable // Optional: Enable searching in the dropdown
                  isDisabled={formik.values.asset_owner === 1}
                />
                {formik.touched.asset_company_id &&
                  formik.errors.asset_company_id && (
                    <FormHelperText className="error-message">
                      {formik.errors.asset_company_id}
                    </FormHelperText>
                  )}
              </Grid>
              {/* Current Value (Per Company Act) */}
              {formik.values.asset_owner === 2 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Current Value (Per Company Act)
                    <Typography
                      variant="body1"
                      component="label"
                      className="asterisk"
                    >
                      *
                    </Typography>
                  </Typography>
                  <Box className="field-icon">
                    <div style={{ position: "relative" }}>
                      <TextField
                        inputRef={CompanyInputRef}
                        onWheel={handleWheelChange}
                        onKeyDown={handleKeyDown}
                        // type="number"
                        className="input-field"
                        {...getFieldProps({
                          name: "current_value_per_company_act",
                          required: true,
                          placeholder: "Enter Current Value(Per Company Act)",
                        })}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Typography
                                variant="body1"
                                component="label"
                                className="rupee-symbol"
                              >
                                ₹
                              </Typography>
                            </InputAdornment>
                          ),
                          style: { paddingLeft: "40px" },
                        }}
                        onBlur={(e) => {
                          const { value } = e.target;
                          const roundedValue = roundToTwoDecimal(value);
                          formik.setFieldValue(
                            "current_value_per_company_act",
                            roundedValue
                          );
                        }}
                      />
                    </div>
                  </Box>
                </Grid>
              )}
              {/* Asset Branch */}
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  Asset Branch
                  <Typography
                    variant="body1"
                    component="label"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </Typography>
                <Select
                  placeholder="Select Asset Branch"
                  name="asset_branch_id"
                  options={branchList}
                  className={`muilt-select-field ${
                    formik.touched.asset_branch_id &&
                    formik.errors.asset_branch_id
                      ? "has-error"
                      : ""
                  }`}
                  classNamePrefix="select"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 10,
                    colors: {
                      ...theme.colors,
                      primary25: "var(--secondary-text-color)",
                      primary: "var(--green)",
                    },
                  })}
                  onBlur={formik.handleBlur}
                  onChange={(selectedOption) => {
                    formik.setFieldValue(
                      "asset_branch_id",
                      selectedOption?.value
                    );
                    formik.setFieldTouched("asset_branch_id", false);
                  }}
                  value={
                    formik.values.asset_branch_id
                      ? branchList.find(
                          (option) =>
                            option.value === formik.values.asset_branch_id
                        )
                      : null
                  }
                  isSearchable // Optional: Enable searching in the dropdown
                />
                {formik.touched.asset_branch_id &&
                  formik.errors.asset_branch_id && (
                    <FormHelperText className="error-message">
                      {formik.errors.asset_branch_id}
                    </FormHelperText>
                  )}
              </Grid>
              {/* Current Value (Per IT Act) */}
              {formik.values.asset_owner === 2 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Box>
                    <Typography variant="body1" component="label">
                      Current Value (Per IT Act)
                      <Typography
                        variant="body1"
                        component="label"
                        className="asterisk"
                      >
                        *
                      </Typography>
                    </Typography>
                    <Box className="field-icon">
                      <div style={{ position: "relative" }}>
                        <TextField
                          inputRef={ITValueInputRef}
                          onWheel={handleWheelChange}
                          onKeyDown={handleKeyDown}
                          // type="number"
                          className="input-field"
                          {...getFieldProps({
                            name: "current_value_per_it_act",
                            required: true,
                            placeholder: "Enter Current Value(Per IT Act)",
                          })}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Typography
                                  variant="body1"
                                  component="label"
                                  className="rupee-symbol"
                                >
                                  ₹
                                </Typography>
                              </InputAdornment>
                            ),
                            style: { paddingLeft: "40px" },
                          }}
                          onBlur={(e) => {
                            const { value } = e.target;
                            const roundedValue = roundToTwoDecimal(value);
                            formik.setFieldValue(
                              "current_value_per_it_act",
                              roundedValue
                            );
                          }}
                        />
                      </div>
                    </Box>
                  </Box>
                </Grid>
              )}
              {/* Assign Date */}
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  Assign Date
                  <Typography
                    variant="body1"
                    component="label"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        fullWidth
                        id="outlined-basic-date"
                        label="Assign Date"
                        variant="outlined"
                        size="small"
                        error={
                          formik.touched.assign_date &&
                          Boolean(formik.errors.assign_date)
                        }
                        placeholder="Select Assign Date"
                        name="assign_date"
                        value={formik.values.assign_date}
                        onBlur={() =>
                          formik.setFieldTouched("assign_date", false)
                        }
                      />
                    )}
                    className={`input-field ${
                      formik.touched.assign_date && formik.errors.assign_date
                        ? "error"
                        : ""
                    }`}
                    onChange={(date) => {
                      formik.setFieldValue("assign_date", date);
                    }}
                    format="dd-MM-yyyy"
                  />
                  {formik.touched.assign_date && formik.errors.assign_date && (
                    <FormHelperText className="error-message">
                      {formik.errors.assign_date}
                    </FormHelperText>
                  )}
                </LocalizationProvider>
              </Grid>
              {/* Assign To */}
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Box>
                  <Typography variant="body1" component="label">
                    Assign To
                    <Typography
                      variant="body1"
                      component="label"
                      className="asterisk"
                    >
                      *
                    </Typography>
                  </Typography>
                  <Select
                    placeholder="Select Assign To"
                    name="assign_to"
                    options={userList}
                    className={`muilt-select-field ${
                      formik.touched.assign_to && formik.errors.assign_to
                        ? "has-error"
                        : ""
                    }`}
                    classNamePrefix="select"
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 10,
                      colors: {
                        ...theme.colors,
                        primary25: "var(--secondary-text-color)",
                        primary: "var(--green)",
                      },
                    })}
                    onBlur={formik.handleBlur}
                    onChange={(selectedOption) => {
                      formik.setFieldValue("assign_to", selectedOption?.value);
                      formik.setFieldTouched("assign_to", false);
                    }}
                    value={userList.find(
                      (option) => option.value === formik.values.assign_to
                    )}
                    isSearchable
                  />
                  {formik.touched.assign_to && formik.errors.assign_to && (
                    <FormHelperText className="error-message">
                      {formik.errors.assign_to}
                    </FormHelperText>
                  )}
                </Box>
              </Grid>
              {/* Current Status */}
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Box>
                  <Typography variant="body1" component="label">
                    Current Status
                    <Typography
                      variant="body1"
                      component="label"
                      className="asterisk"
                    >
                      *
                    </Typography>
                  </Typography>
                  <Select
                    placeholder="Select Current Status"
                    name="current_status"
                    options={statusList}
                    className={`muilt-select-field ${
                      formik.touched.current_status &&
                      formik.errors.current_status
                        ? "has-error"
                        : ""
                    }`}
                    classNamePrefix="select"
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 10,
                      colors: {
                        ...theme.colors,
                        primary25: "var(--secondary-text-color)",
                        primary: "var(--green)",
                      },
                    })}
                    onBlur={formik.handleBlur}
                    onChange={(selectedOption) => {
                      formik.setFieldValue(
                        "current_status",
                        selectedOption?.value || ""
                      );
                      formik.setFieldTouched("current_status", false);
                    }}
                    value={statusList.find(
                      (option) => option.value === formik.values.current_status
                    )}
                    isSearchable // Optional: Enable searching in the dropdown
                  />
                  {formik.touched.current_status &&
                    formik.errors.current_status && (
                      <FormHelperText className="error-message">
                        {formik.errors.current_status}
                      </FormHelperText>
                    )}
                </Box>
              </Grid>

              {/* Warranty */}
              {formik.values.asset_owner === 2 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  {/* <Stack spacing={2}> */}

                  <Box>
                    <Typography variant="body1" component="label">
                      Warranty
                      <Typography
                        variant="body1"
                        component="label"
                        className="asterisk"
                      >
                        *
                      </Typography>
                    </Typography>
                    <Box className="warranty-wrapper">
                      <Box className="w-full">
                        <Select
                          name="warranty_period"
                          options={YearList}
                          className={`muilt-select-field ${
                            formik.touched.warranty_period &&
                            formik.errors.warranty_period
                              ? "has-error"
                              : ""
                          }`}
                          placeholder="Select Year"
                          classNamePrefix="select"
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 10,
                            colors: {
                              ...theme.colors,
                              primary25: "var(--secondary-text-color)",
                              primary: "var(--green)",
                            },
                          })}
                          onBlur={formik.handleBlur}
                          onChange={(selectedOption) => {
                            setYear(selectedOption?.value);
                            formik.setFieldValue(
                              "warranty_period",
                              selectedOption?.value || "0" + "-" + month
                                ? month
                                : "0"
                            );
                            formik.setFieldTouched("warranty_period", true);
                          }}
                          value={YearList.find(
                            (option) => option.value === year
                          )}
                          isSearchable // Optional: Enable searching in the dropdown
                        />
                      </Box>
                      <Box className="w-full">
                        <Select
                          name="warranty_period"
                          options={monthList}
                          className={`muilt-select-field ${
                            formik.touched.warranty_period &&
                            formik.errors.warranty_period
                              ? "has-error"
                              : ""
                          }`}
                          placeholder="Select Month"
                          classNamePrefix="select"
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 10,
                            colors: {
                              ...theme.colors,
                              primary25: "var(--secondary-text-color)",
                              primary: "var(--green)",
                            },
                          })}
                          onBlur={formik.handleBlur}
                          onChange={(selectedOption) => {
                            setMonth(selectedOption?.value);
                            formik.setFieldValue(
                              "warranty_period",
                              selectedOption?.value || ""
                            );
                            formik.setFieldTouched(
                              "warranty_period",
                              year ? year : "0" - selectedOption?.value
                            );
                          }}
                          value={monthList.find(
                            (option) => option.value === month
                          )}
                          isSearchable // Optional: Enable searching in the dropdown
                        />
                      </Box>
                    </Box>
                    {formik?.touched?.warranty_period &&
                      formik?.errors?.warranty_period && (
                        <FormHelperText className="error-message">
                          {formik?.errors?.warranty_period}
                        </FormHelperText>
                      )}
                  </Box>
                  {/* </Stack> */}
                </Grid>
              )}

              {/* Asset Description */}
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  Asset Description
                  <Typography
                    variant="body1"
                    component="label"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </Typography>
                <TextField
                  multiline
                  rows={4.5}
                  id="asset_description"
                  className="input-field"
                  {...getFieldProps({
                    name: "asset_description",
                    required: true,
                    placeholder: "Add Asset Description",
                  })}
                  onBlur={() =>
                    formik.setFieldTouched("asset_description", false)
                  }
                />
                {/* {formik.touched.asset_description &&
                  formik.errors.asset_description && (
                    <FormHelperText className="error-message">
                      {formik.errors.asset_description}
                    </FormHelperText>
                  )} */}
              </Grid>
              {/* Warranty Description */}
              {formik.values.asset_owner === 2 && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Warranty Description
                    <Typography
                      variant="body1"
                      component="label"
                      className="asterisk"
                    >
                      *
                    </Typography>
                  </Typography>
                  <TextField
                    multiline
                    rows={4.5}
                    id="Warranty_description"
                    name="Warranty_description"
                    className="input-field"
                    {...getFieldProps({
                      name: "Warranty_description",
                      required: true,
                      placeholder: "Add Warranty Description",
                    })}
                    onBlur={() =>
                      formik.setFieldTouched("Warranty_description", false)
                    }
                  />
                  {/* {formik.touched.Warranty_description &&
                  formik.errors.Warranty_description && (
                    <FormHelperText className="error-message">
                      {formik.errors.Warranty_description}
                    </FormHelperText>
                  )} */}
                </Grid>
              )}
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className="footer-wrapper">
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  className="primary-btn btn w-120"
                  disableRipple
                  disabled={loader}
                  endIcon={
                    loader && <CircularProgress size={20} color="inherit" />
                  }
                >
                  Save
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  className="secondary-btn btn w-120"
                  disableRipple
                  onClick={() => {
                    navigate("/assets");
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Grid>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default AssetCreate;
