import { configureStore, combineReducers } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import sidebarReducer from "./slices/drawerHandlerSlice.js";
import authReducer from "./slices/authslice";
import feedbackReducer from "./slices/feedbackSlice";
import assetCategoryReducer from "./slices/assetCategorySlice";
import userReducer from "./slices/userSlice";
import userByIdReducer from "./slices/userbyIdSlice";
import companyBranchReducer from "./slices/companyBranchSlice";
import venderReducer from "./slices/vendorSlice";
import reportReducer from "./slices/reportSlice";
import purchaseRequestReducer from "./slices/purchaseRequestSlice";
import dashboardReducer from "./slices/dashboardSlice";
import assetReducer from "./slices/assetSlice";
import createSettingReducer from "./slices/CreateActivitySetting";
import permissionReducer from "./slices/permissionSlice";
import applicationPermissionReducer from "./slices/applicationPermissionSlice";
import announcementReducer from "./slices/announcementSlice.js";
import userListReducer from "./slices/listAuthorSlice.js";
const rootReducer = combineReducers({
  auth: authReducer,
  sidebar:sidebarReducer,
  assetCategory: assetCategoryReducer,
  feedback: feedbackReducer,
  users: userReducer,
  usersById: userByIdReducer,
  companyBranch: companyBranchReducer,
  vendor: venderReducer,
  report: reportReducer,
  createSetting: createSettingReducer,
  purchaseRequestReducer: purchaseRequestReducer,
  dashboard: dashboardReducer,
  asset: assetReducer,
  permissions: permissionReducer,
  applicationPermissions: applicationPermissionReducer,
  announcements: announcementReducer,
  userList: userListReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
});

export default store;
