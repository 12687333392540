import React, { useLayoutEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import NoAccess from "../components/common/noAccess";
import Layout from "../components/layout";
import getTokenFromCookies from "../utils/token";
import { getHasPermission } from "../utils/userPermissions";
import Loader from "../components/common/Loader";

import { makeSelectUserPermissions } from "../components/common/selector"; // Import memoized selector

const ProtectedRoute = ({
  permission: requiredPermission = {},
  component: Component,
}) => {
  const [hasAccess, setHasAccess] = useState(false);
  const [isFetching, setIsFetching] = useState(true);

  // Use memoized selector
  const { userPermissions } = useSelector(makeSelectUserPermissions());

  const isLoading = useMemo(() => {
    // status === "loading";
    return false;
  }, []); // No dependencies since this is a placeholder

  let token = getTokenFromCookies();

  useLayoutEffect(() => {
    // Reset state when permission changes
    setIsFetching(() => false);
    setHasAccess(() => true);

    const hasPermissionFetched = !!Object.keys(userPermissions)?.length;
    if (!hasPermissionFetched) return;

    const access = getHasPermission(requiredPermission, userPermissions);

    setHasAccess(() => access);
    setIsFetching(() => false);

    return () => {
      setHasAccess(() => true);
      setIsFetching(() => false);
    };
  }, [requiredPermission, userPermissions]); // Dependency on requiredPermission and userPermissions

  if (isLoading) return <Loader />;

  return token ? (
    <>
      {isFetching ? (
        <Layout>
          <Loader />
        </Layout>
      ) : hasAccess ? (
        <Layout>{Component}</Layout>
      ) : (
        <NoAccess />
      )}
    </>
  ) : (
    <Navigate to="/signin" replace={true} />
  );
};

export default ProtectedRoute;
